.slider {
  margin-top:0;
  margin-bottom:20px;
}
.noUi-base { background-color: #D4F500; }
.panel .panel-heading { border-bottom:4px solid transparent; }
/*
.zeiterfassung:nth-child(1) .panel-heading{ border-color:#4C935F; }
.zeiterfassung:nth-child(2) .panel-heading{ border-color:#366F55; }
.zeiterfassung:nth-child(3) .panel-heading{ border-color:#277D7E; }
.zeiterfassung:nth-child(4) .panel-heading{ border-color:#2A6257; }
.zeiterfassung:nth-child(5) .panel-heading{ border-color:#1D6C7A; }
.zeiterfassung:nth-child(6) .panel-heading{ border-color:#5B8838; }
.zeiterfassung:nth-child(7) .panel-heading{ border-color:#3A937D; }
.zeiterfassung:nth-child(8) .panel-heading{ border-color:#399582; }
.zeiterfassung:nth-child(9) .panel-heading{ border-color:#265B54; }
.zeiterfassung:nth-child(10) .panel-heading{ border-color:#184F5B; }
.zeiterfassung:nth-child(11) .panel-heading { border-color:#00ccff; }
.zeiterfassung:nth-child(12) .panel-heading { border-color:#0099ff; }
.zeiterfassung:nth-child(13) .panel-heading { border-color:#33ff00; }
.zeiterfassung:nth-child(14) .panel-heading { border-color:#6600ff; }
.zeiterfassung:nth-child(15) .panel-heading { border-color:#cc00ff; }
.zeiterfassung:nth-child(16) .panel-heading { border-color:#ff00cc; }
.zeiterfassung:nth-child(17) .panel-heading { border-color:#ff0066; }
.zeiterfassung:nth-child(18) .panel-heading { border-color:#ff3366; }
.zeiterfassung:nth-child(19) .panel-heading { border-color:#00ff99; }
.zeiterfassung:nth-child(20) .panel-heading { border-color:#ff99cc; }
.zeiterfassung:nth-child(21) .panel-heading { border-color:#ffcc99; }
.zeiterfassung:nth-child(22) .panel-heading { border-color:#ff9966; }
.zeiterfassung:nth-child(23) .panel-heading { border-color:#ff6633; }
.zeiterfassung:nth-child(24) .panel-heading { border-color:#ff3300; }
.zeiterfassung:nth-child(25) .panel-heading { border-color:#ff6633; }
.zeiterfassung:nth-child(26) .panel-heading { border-color:#ff9900; }
.zeiterfassung:nth-child(27) .panel-heading { border-color:#ffcc33; }
.zeiterfassung:nth-child(28) .panel-heading { border-color:#4C935F; }
.zeiterfassung:nth-child(29) .panel-heading { border-color:#33cc00; }
.zeiterfassung:nth-child(30) .panel-heading { border-color:#00cc33; }
.zeiterfassung:nth-child(31) .panel-heading { border-color:#00ff99; }*/

.c-0-color.panel-heading{ border-color:#4C935F; }
.c-1-color.panel-heading{ border-color:#366F55; }
.c-2-color.panel-heading{ border-color:#277D7E; }
.c-3-color.panel-heading{ border-color:#2A6257; }
.c-4-color.panel-heading{ border-color:#1D6C7A; }
.c-5-color.panel-heading{ border-color:#5B8838; }
.c-6-color.panel-heading{ border-color:#3A937D; }
.c-7-color.panel-heading{ border-color:#399582; }
.c-8-color.panel-heading{ border-color:#265B54; }
.c-9-color.panel-heading{ border-color:#184F5B; }
.c-10-color.panel-heading { border-color:#00ccff; }
.c-11-color.panel-heading { border-color:#0099ff; }
.c-12-color.panel-heading { border-color:#33ff00; }
.c-13-color.panel-heading { border-color:#6600ff; }
.c-14-color.panel-heading { border-color:#cc00ff; }
.c-15-color.panel-heading { border-color:#ff00cc; }
.c-16-color.panel-heading { border-color:#ff0066; }
.c-17-color.panel-heading { border-color:#ff3366; }
.c-18-color.panel-heading { border-color:#00ff99; }
.c-19-color.panel-heading { border-color:#ff99cc; }
.c-20-color.panel-heading { border-color:#ffcc99; }
.c-21-color.panel-heading { border-color:#ff9966; }
.c-22-color.panel-heading { border-color:#ff6633; }
.c-23-color.panel-heading { border-color:#ff3300; }
.c-24-color.panel-heading { border-color:#ff6633; }
.c-25-color.panel-heading { border-color:#ff9900; }
.c-26-color.panel-heading { border-color:#ffcc33; }
.c-27-color.panel-heading { border-color:#4C935F; }
.c-28-color.panel-heading { border-color:#33cc00; }
.c-29-color.panel-heading { border-color:#00cc33; }
.c-30-color.panel-heading { border-color:#00ff99; }

.noUi-connect.c-0-color { background:#4C935F; }
.noUi-connect.c-1-color { background:#366F55; }
.noUi-connect.c-2-color { background:#277D7E; }
.noUi-connect.c-3-color { background:#2A6257; }
.noUi-connect.c-4-color { background:#1D6C7A; }
.noUi-connect.c-5-color { background:#5B8838; }
.noUi-connect.c-6-color { background:#3A937D; }
.noUi-connect.c-7-color { background:#399582; }
.noUi-connect.c-8-color { background:#265B54; }
.noUi-connect.c-9-color { background:#184F5B; }
.noUi-connect.c-10-color { background:#00ccff; }
.noUi-connect.c-11-color { background:#0099ff; }
.noUi-connect.c-12-color { background:#33ff00; }
.noUi-connect.c-13-color { background:#6600ff; }
.noUi-connect.c-14-color { background:#cc00ff; }
.noUi-connect.c-15-color { background:#ff00cc; }
.noUi-connect.c-16-color { background:#ff0066; }
.noUi-connect.c-17-color { background:#ff3366; }
.noUi-connect.c-18-color { background:#00ff99; }
.noUi-connect.c-19-color { background:#ff99cc; }
.noUi-connect.c-20-color { background:#ffcc99; }
.noUi-connect.c-21-color { background:#ff9966; }
.noUi-connect.c-22-color { background:#ff6633; }
.noUi-connect.c-23-color { background:#ff3300; }
.noUi-connect.c-24-color { background:#ff6633; }
.noUi-connect.c-25-color { background:#ff9900; }
.noUi-connect.c-26-color { background:#ffcc33; }
.noUi-connect.c-27-color { background:#4C935F; }
.noUi-connect.c-28-color { background:#33cc00; }
.noUi-connect.c-29-color { background:#00cc33; }
.noUi-connect.c-30-color { background:#00ff99; }

.saved-stundenzettel { display:block; padding: 3px 0 3px 10px; border-left:8px solid transparent }

.saved-stundenzettel:nth-child(1) { border-color: #4C935F; }
.saved-stundenzettel:nth-child(2) { border-color: #366F55; }
.saved-stundenzettel:nth-child(3) { border-color: #277D7E; }
.saved-stundenzettel:nth-child(4) { border-color: #2A6257; }
.saved-stundenzettel:nth-child(5) { border-color: #1D6C7A; }
.saved-stundenzettel:nth-child(6) { border-color: #5B8838; }
.saved-stundenzettel:nth-child(7) { border-color: #3A937D; }
.saved-stundenzettel:nth-child(8) { border-color: #399582; }
.saved-stundenzettel:nth-child(9) { border-color: #265B54; }
.saved-stundenzettel:nth-child(10) { border-color: #184F5B; }
.saved-stundenzettel:nth-child(11) { border-color: #00ccff; }
.saved-stundenzettel:nth-child(12) { border-color: #0099ff; }
.saved-stundenzettel:nth-child(13) { border-color: #33ff00; }
.saved-stundenzettel:nth-child(14) { border-color: #6600ff; }
.saved-stundenzettel:nth-child(15) { border-color: #cc00ff; }
.saved-stundenzettel:nth-child(16) { border-color: #ff00cc; }
.saved-stundenzettel:nth-child(17) { border-color: #ff0066; }
.saved-stundenzettel:nth-child(18) { border-color: #ff3366; }
.saved-stundenzettel:nth-child(19) { border-color: #00ff99; }
.saved-stundenzettel:nth-child(20) { border-color: #ff99cc; }
.saved-stundenzettel:nth-child(21) { border-color: #ffcc99; }
.saved-stundenzettel:nth-child(22) { border-color: #ff9966; }
.saved-stundenzettel:nth-child(23) { border-color: #ff6633; }
.saved-stundenzettel:nth-child(24) { border-color: #ff3300; }
.saved-stundenzettel:nth-child(25) { border-color: #ff6633; }
.saved-stundenzettel:nth-child(26) { border-color: #ff9900; }
.saved-stundenzettel:nth-child(27) { border-color: #ffcc33; }
.saved-stundenzettel:nth-child(28) { border-color: #4C935F; }
.saved-stundenzettel:nth-child(29) { border-color: #33cc00; }
.saved-stundenzettel:nth-child(30) { border-color: #00cc33; }
.saved-stundenzettel:nth-child(31) { border-color: #00ff99; }