body,html { overflow-x: hidden !important; }
.panel-heading h2 { margin-top:10px; }
.form-signin { max-width:300px; width:100%; }
.form-signin input { margin-bottom:0.5em; }

.select2-container { max-width:100%; min-width:100%; width:100%; }
.btn-lg { font-size:44px; }
.panel-heading .zeiterfassung-time { display:none; font-size:18px; }
.panel-heading .zeiterfassung-title, .panel-heading .title { font-size:18px; cursor:pointer; }
.zeiterfassung.show-time .panel-heading.collapsed .zeiterfassung-time { display:block; }
.zeiterfassung.show-time .panel-heading .zeiterfassung-time { display:none; }
.panel-heading.collapsed .zeiterfassung-title { width: calc(100% - 120px); display: inline-block; }


.zeiterfassung.show-time .zeiterfassung-time { display:inline-block; }
.zeiterfassung.show-time .time-controll { display:block; }

.zeiterfassung .row-time { display:none; }
.zeiterfassung.show-time .row-time { display:block; }

.zeiterfassung-time { font-size:44px; display:none; }
.zeiterfassung-saved .panel-heading .zeiterfassung-time { display:block !important; }
.zeiterfassung-direct { display:none; }
.zeiterfassung-direct-label { display:none; }
.zeiterfassung-close { cursor:pointer; font-size:20px; margin-left:1em; }

.zeiterfassung-abrechnungstyp-row { display:none; }
.zeiterfassung-show-abrechnungstyp .zeiterfassung-abrechnungstyp-row { display:block; }

#main-content { padding-top:97px; }
.noPaddingLeft { padding-left:0;  }
.time-is-running .panel-heading.collapsed { background:#dff0d8; }

.body-notification, .html-notification { min-width:0; min-height:0; cursor:pointer; overflow:hidden !important;  }
.notification-dragger { background:#D5D828; width:100%; height:10px; cursor:move; -webkit-app-region: drag; }
#notification { padding:5px 10px 10px 10px; text-align:center; font-size:20px; -webkit-app-region: no-drag; }

#meinestunden { margin-top:25px; }

.external-link { cursor: pointer; color: #03070a; }
.external-link:hover { color: #777; }
.panel.started .panel-heading { background: #d6ea00; color:#134759; }

.taskdetail-row-comments-comment { margin:5px 0 10px 0; }
.taskdetail-row-text { margin:5px 0 10px 0; }
.taskdetail-row-comments-owner { font-weight:bold;  }
.taskdetail-phase { font-size:14px; display:inline-block; padding:6px 12px; line-height:22px; }
.taskdetail-phase i { margin-right:10px; }

#addTrackingByTaskId { width:calc(100% - 70px); margin-left:30px; display:block; margin-top:5px; }

.sync-status { position:absolute; bottom:20px; left:20px; color:white; }
.sync-status > div > div > span { color:#97a70e; }
.color-red { font-weight:bold; color: #d9534f; }

.selectpicker-projects-kunde { font-weight:bold; }
.selectpicker-projects-project { padding-left:20px; }

.panel-heading .addressdata-title { font-size:18px; cursor:pointer; }
.panel-heading-address { font-size:18px; }
.addressdata-close { cursor:pointer; font-size:20px; margin-left:1em; }
.margintop { margin-top:24px; }
.addressdata-close-all { display:none; }

/** Int Dials */
.card-deck { display: flex; flex-wrap: wrap; justify-content: space-between; }
.card { width: 18%; background: #fff; border: 1px solid lightgray; margin-bottom: 3rem; }
.card img { max-width: 100%; height: auto; }
.card .card-body { padding: 0 1rem; }
.card .card-title { text-align: left; }
.card .card-title span { float: right; }

#errorMessage {
    color:red;
    margin-top:1em;
}

.sidebar-menu li.active a { background: #ffffff2b; }
.sidebar-menu li.active a i { color: #D4F500; }
ul.sidebar-menu li a:focus { background: #ffffff2b; border-radius: 0; }
ul.sidebar-menu li {margin-left:0; margin-right:0;}

ul.sidebar-menu li a:hover i, ul.sidebar-menu li a:focus i { color: #D4F500; }

.select2-container .select2-selection--single {
    height:34px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height:34px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    top:4px;
}
.select2-container--default .select2-selection--single {
    border: 1px solid #cccccc;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.modal-title { display:inline; }
.mb-2 { margin-bottom:20px; }
@media (max-width: 900px) {
    .form-signin { max-width:900px; width:100%; }
}