@import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '/node_modules/select2/dist/css/select2.min.css';
@import '/node_modules/nouislider/dist/nouislider.css';


@import 'bootstrap-theme.css';
@import 'font-awesome.min.css';
@import 'pxc.theme.css';
@import 'app.css';

@import 'nouislider';
@import 'loader';

div:where(.swal2-container) .swal2-html-container {
  font-size: 1.5em !important;
}
.swal2-actions .swal2-cancel { margin-left:20px; }

.collapse-sign {
  position: relative;
  &.collapsed {
    &:after {
      content:"\f067";
    }
  }
  &:after {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 16px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f068";
    position: absolute;
    right: 16px;
    top: 16px;
  }
}