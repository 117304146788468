@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic,800,800italic);

body {
    color: #797979;
    background: #f1f2f7;
    font-family: 'Open Sans', sans-serif;
    padding: 0px !important;
    margin: 0px !important;
    font-size: 13px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    font-weight: 400;
}

ul li {
    list-style: none;
}

a, a:hover, a:focus {
    text-decoration: none;
    outline: none;
}

::selection {
    background: #FF6C60;
    color: #fff;
}

::-moz-selection {
    background: #FF6C60;
    color: #fff;
}

#container {
    width: 100%;
    height: 100%;
}

.login-body {
    background-color: #f1f2f7;
}

.form-signin .checkbox {
    margin-bottom: 14px;
}

.form-signin .checkbox {
    font-weight: 400;
    color: #b6b6b6;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
}

.form-signin .form-control:focus {
    z-index: 2;
}

.form-signin a {
    color: #41cac0;
}

.form-signin a:hover {
    color: #b6b6b6;
}

.login-social-link a:hover {
    color: #fff;
}

.login-social-link a i {
    font-size: 20px;
    padding-right: 10px;
}

.login-social-link a.twitter {
    background: #44ccfe;
    box-shadow: 0 4px #2bb4e8;
    float: left;
}

#sidebar {
    background: #134759 none repeat scroll 0 0;
    height: 100%;
    position: fixed;
    width: 210px;
}

#sidebar ul li {
    position: relative;
}

#sidebar .sub-menu > .sub li {
    padding-left: 32px;
}

#sidebar .sub-menu > .sub li:last-child {
    padding-bottom: 10px;
}

.dcjq-icon {
    height: 17px;
    width: 17px;
    display: inline-block;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    position: absolute;
    right: 10px;
    top: 15px;
}

.active .dcjq-icon {
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

.nav-collapse.collapse {
    display: inline;
}

ul.sidebar-menu, ul.sidebar-menu li ul.sub {
    margin: -2px 0 0;
    padding: 0;
}

ul.sidebar-menu {
    margin-top: 94px;
}

#sidebar > ul > li > ul.sub {
    display: none;
}

#sidebar > ul > li.active > ul.sub, #sidebar > ul > li > ul.sub > li > a {
    display: block;
}

ul.sidebar-menu li ul.sub li {
    background: #ffffff2b;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
}

ul.sidebar-menu li ul.sub li:last-child {
    border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
}

ul.sidebar-menu li ul.sub li a {
    font-size: 12px;
    padding: 6px 0;
    line-height: 35px;
    height: 35px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: #aeb2b7;
}

ul.sidebar-menu li ul.sub li a:hover, ul.sidebar-menu li ul.sub li.active a {
    color: #D4F500;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    display: block;
}

ul.sidebar-menu li {
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 10px;
}

ul.sidebar-menu li.sub-menu {
    line-height: 15px;
}

ul.sidebar-menu li a span {
    display: inline-block;
}

ul.sidebar-menu li a {
    color: #bbc1c7;
    text-decoration: none;
    display: block;
    padding: 15px 0 15px 10px;
    font-size: 13px;
    outline: none;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

ul.sidebar-menu li a.active, ul.sidebar-menu li a:hover, ul.sidebar-menu li a:focus {
    background: #ffffff2b;
    color: #bbc1c7;
    display: block;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

ul.sidebar-menu li a i {
    font-size: 15px;
    padding-right: 6px;
}

ul.sidebar-menu li a:hover i, ul.sidebar-menu li a:focus i {
    color: #D4F500;
}

ul.sidebar-menu li a.active i {
    color: #D4F500;
}

.mail-info, .mail-info:hover {
    margin: -3px 6px 0 0;
    font-size: 11px;
}

#main-content {
    margin-left: 210px;
}

.main-content-body {
    margin-bottom: 1rem;
}

.header, .footer {
    min-height: 60px;
    padding: 0 15px;
}

.header {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1040;
}

.white-bg {
    background: #fff;
}

.wrapper {
    display: inline-block;
    margin-top: 60px;
    padding: 15px;
    width: 100%;
}

a.logo {
    font-size: 21px;
    color: #2e2e2e;
    float: left;
    text-transform: uppercase;
    width: 73%;
}

a.logo:hover, a.logo:focus {
    text-decoration: none;
    outline: none;
}

a.logo span {
    color: #FF6C60;
}

/*notification*/
#top_menu .nav > li, ul.top-menu > li {
    float: left;
}

.notify-row {
    float: left;
    margin-left: 16px;
    margin-top: 27px;
}

.notify-row .notification span.label {
    display: inline-block;
    height: 18px;
    width: 20px;
    padding: 5px;
}

ul.top-menu > li > a {
    color: #666666;
    font-size: 16px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    border: 1px solid #f0f0f8 !important;
    padding: 2px 6px;
    margin-right: 15px;
}

ul.top-menu > li > a:hover, ul.top-menu > li > a:focus {
    border: 1px solid #f0f0f8 !important;
    background-color: #fff !important;
    border-color: #f0f0f8 !important;
    text-decoration: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    color: #2E2E2E !important;
}

.notify-row .badge {
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 100;
}

.dropdown-menu.extended {
    max-width: 300px !important;
    min-width: 160px !important;
    top: 42px;
    width: 235px !important;
    padding: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.176) !important;
    border: none !important;
    border-radius: 4px;
    -webkit-border-radius: 4px;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {

    /* Safari and Chrome */
    .dropdown-menu.extended {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.176) !important;
    }

}

.dropdown-menu.extended li p {
    background-color: #F1F2F7;
    color: #666666;
    margin: 0;
    padding: 10px;
    border-radius: 4px 4px 0px 0px;
    -webkit-border-radius: 4px 4px 0px 0px;
}

.dropdown-menu.extended li p.green {
    background-color: #a9d86e;
    color: #fff;
}

.dropdown-menu.extended li p.red {
    background-color: #ff6c60;
    color: #fff;
}

.dropdown-menu.extended li p.yellow {
    background-color: #fcb322;
    color: #fff;
}

.dropdown-menu.extended li a {
    border-bottom: 1px solid #EBEBEB !important;
    font-size: 12px;
    list-style: none;
}

.dropdown-menu.extended li a {
    padding: 15px 10px !important;
    width: 100%;
    display: inline-block;
}

.dropdown-menu.extended li a:hover {
    background-color: #F7F8F9 !important;
    color: #2E2E2E;
}

.dropdown-menu.tasks-bar .task-info .desc {
    font-size: 13px;
    font-weight: normal;
}

.dropdown-menu.tasks-bar .task-info .percent {
    display: inline-block;
    float: right;
    font-size: 13px;
    font-weight: 600;
    padding-left: 10px;
    margin-top: -4px;
}

.dropdown-menu.extended .progress {
    margin-bottom: 0 !important;
    height: 10px;
}

.dropdown-menu.inbox li a .photo img {
    border-radius: 2px 2px 2px 2px;
    float: left;
    height: 40px;
    margin-right: 4px;
    width: 40px;
}

.dropdown-menu.inbox li a .subject {
    display: block;
}

.dropdown-menu.inbox li a .subject .from {
    font-size: 12px;
    font-weight: 600;
}

.dropdown-menu.inbox li a .subject .time {
    font-size: 11px;
    font-style: italic;
    font-weight: bold;
    position: absolute;
    right: 5px;
}

.dropdown-menu.inbox li a .message {
    display: block !important;
    font-size: 11px;
}

.top-nav {
    margin-right: 16px;
    margin-top: 20px;
}

.top-nav ul.top-menu > li .dropdown-menu.logout {
    width: 268px !important;
}

.top-nav li.dropdown .dropdown-menu {
    float: right;
    right: 0;
    left: auto;
}

.dropdown-menu.extended.logout > li {
    float: left;
    text-align: center;
    width: 33.3%;
}

.dropdown-menu.extended.logout > li:last-child {
    float: left;
    text-align: center;
    width: 100%;
    background: #a9d96c;
    border-radius: 0 0 3px 3px;
}

.dropdown-menu.extended.logout > li:last-child > a, .dropdown-menu.extended.logout > li:last-child > a:hover {
    color: #fff;
    border-bottom: none !important;
    text-transform: uppercase;
}

.dropdown-menu.extended.logout > li:last-child > a:hover > i {
    color: #fff;
}

.dropdown-menu.extended.logout > li > a {
    color: #a4abbb;
    border-bottom: none !important;
}

.full-width .dropdown-menu.extended.logout > li > a:hover {
    background: none !important;
    color: #50c8ea !important;
}

.dropdown-menu.extended.logout > li > a:hover {
    background: none !important;
}

.dropdown-menu.extended.logout > li > a:hover i {
    color: #50c8ea;
}

.dropdown-menu.extended.logout > li > a i {
    font-size: 17px;
}

.dropdown-menu.extended.logout > li > a > i {
    display: block;
}

.top-nav .username {
    font-size: 13px;
    color: #555555;
}

.top-nav ul.top-menu > li > a {
    border: 1px solid #eeeeee;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    padding: 6px;
    background: none;
    margin-right: 0;
}

.top-nav ul.top-menu > li {
    margin-left: 20px;
}

.top-nav ul.top-menu > li > a:hover, .top-nav ul.top-menu > li > a:focus {
    border: 1px solid #F1F2F7;
    background: #F1F2F7;
}

.top-nav .dropdown-menu.extended.logout {
    top: 50px;
}

.top-nav .nav .caret {
    border-bottom-color: #A4AABA;
    border-top-color: #A4AABA;
}

.top-nav ul.top-menu > li > a:hover .caret {
    border-bottom-color: #000;
    border-top-color: #000;
}

.log-arrow-up {
    background: url("../img/arrow-up.png") no-repeat;
    width: 20px;
    height: 11px;
    position: absolute;
    right: 20px;
    top: -10px;
}

/*----*/
.notify-arrow {
    border-style: solid;
    border-width: 0 9px 9px;
    height: 0;
    margin-top: 0;
    opacity: 0;
    position: absolute;
    left: 7px;
    top: -18px;
    transition: all 0.25s ease 0s;
    width: 0;
    z-index: 10;
    margin-top: 10px;
    opacity: 1;
}

.notify-arrow-yellow {
    border-color: transparent transparent #FCB322;
    border-bottom-color: #FCB322 !important;
    border-top-color: #FCB322 !important;
}

.notify-arrow-red {
    border-color: transparent transparent #ff6c60;
    border-bottom-color: #ff6c60 !important;
    border-top-color: #ff6c60 !important;
}

.notify-arrow-green {
    border-color: transparent transparent #a9d86e;
    border-bottom-color: #a9d86e !important;
    border-top-color: #a9d86e !important;
}

/*search*/
.search {
    margin-top: 6px;
    width: 20px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    border: 1px solid #fff;
    box-shadow: none;
    background: url("/img/search-icon.png") no-repeat 10px 8px;
    padding: 0 5px 0 35px;
    color: #000;
}

.search:focus {
    margin-top: 5px;
    width: 180px;
    border: 1px solid #eaeaea;
    box-shadow: none;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    color: #c8c8c8;
    font-weight: 300;
}

/*--sidebar toggle---*/
.sidebar-toggle-box {
    float: left;
    padding-right: 15px;
    padding-top: 8px;
    cursor: pointer;  /*  margin-top: 20px;*/
}

.sidebar-toggle-box .fa-bars {
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
}

.sidebar-closed > #sidebar > ul {
    display: none;
}

.sidebar-closed #main-content {
    margin-left: 0px;
}

.sidebar-closed #sidebar {
    margin-left: -210px;
}

/*state overview*/
.state-overview .symbol, .state-overview .value {
    display: inline-block;
    text-align: center;
}

.state-overview .value {
    float: right;
    position: relative;
}

.state-overview .value  span {
    color: #c6cad6;
    font-size: 30px;
    font-weight: 300;
    position: relative;
    font-weight: 700;
}

.state-overview .value h1, .state-overview .value p {
    margin: 0;
    padding: 0;
    color: #c6cad6;
    font-weight: 400;
    font-size: 16px;
}

.state-overview .value h1 {
    font-weight: 700;
    display: inline-block;
    font-size: 30px;
}

.state-overview .symbol i {
    color: #fff;
    font-size: 50px;
}

.state-overview .symbol {
    width: 40%;
    padding: 25px 15px;
    -webkit-border-radius: 4px 0px 0px 4px;
    border-radius: 4px 0px 0px 4px;
}

.state-overview .value {
    width: 58%;
    padding-top: 21px;
}

.state-overview .terques, .state-overview .red, .state-overview .yellow, .state-overview .blue {
    background: #566c7e;
}

/*.state-overview .red {
background: #566c7e;
}

.state-overview .yellow {
background: #566c7e;
}

.state-overview .blue {
background: #566c7e;
}*/

/*main chart*/

.border-head h3 {
    border-bottom: 1px solid #c9cdd7;
    margin-top: 0;
    margin-bottom: 20px;
    padding-bottom: 5px;
    font-weight: normal;
    font-size: 18px;
    display: inline-block;
    width: 100%;
    font-weight: 300;
}

.custom-bar-chart {
    border-bottom: 1px solid #c9cdd7;
    height: 266px;
    margin-left: 10px;
    margin-top: 20px;
    position: relative;
    margin-bottom: 50px;
}

.custom-bar-chart .bar {
    height: 100%;
    position: relative;
    width: 4.3%;
    margin: 0px 2%;
    float: left;
    text-align: center;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0x;
    border-radius: 5px 5px 0 0;
    z-index: 10;
}

.custom-bar-chart .bar .title {
    position: absolute;
    bottom: -30px;
    width: 100%;
    text-align: center;
    font-size: 12px;
}

.custom-bar-chart .bar .value {
    position: absolute;
    bottom: 0;
    background: #bfc2cd;
    color: #bfc2cd;
    width: 100%;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.custom-bar-chart .bar .value:hover {
    background: #e8403f;
    color: #fff;
}

.y-axis {
    color: #555555;
    position: absolute;
    text-align: right;
    width: 100%;
}

.y-axis li {
    display: block;
    height: 63px;
    width: 100%;
    border-top: 1px dashed #dbdce0;
}

.y-axis li:last-child {
    border-top: none;
}

.y-axis li span {
    display: block;
    margin: -10px 0 0 -40px;
    padding: 0 10px;
    width: 40px;
}

.y-axis {
    color: #555555;
    text-align: right;
}

/*spark line*/
.chart {
    display: inline-block;
    text-align: center;
    width: 100%;
}

.chart .heading {
    text-align: left;
}

.chart .heading span {
    display: block;
}

.panel.green-chart .chart-tittle {
    font-size: 16px;
    padding: 15px;
    display: inline-block;
    font-weight: normal;
    background: #99c262;
    width: 100%;
    -webkit-border-radius: 0px 0px 4px 4px;
    border-radius: 0px 0px 4px 4px;
}

#barchart {
    margin-bottom: -15px;
    display: inline-block;
}

.chart-tittle .title {
}

.panel.green-chart .chart-tittle .value {
    float: right;
    color: #c0f080;
}

.panel.green-chart {
    background: #a9d96c;
    color: #fff;
}

.panel.terques-chart {
    background: #41cac0;
    color: #fff;
}

.panel.terques-chart .chart-tittle .value {
    float: right;
    color: #fff;
}

.panel.terques-chart .chart-tittle .value a {
    color: #fff;
    font-size: 12px;
}

.panel.terques-chart .chart-tittle .value a:hover, .panel.terques-chart .chart-tittle .value a.active {
    color: #55f2e7;
    font-size: 12px;
}

.panel.terques-chart .chart-tittle {
    font-size: 16px;
    padding: 15px;
    display: inline-block;
    font-weight: normal;
    background: #39b7ac;
    width: 100%;
    -webkit-border-radius: 0px 0px 4px 4px;
    border-radius: 0px 0px 4px 4px;
}

.inline-block {
    display: inline-block;
}

/**/
.panel-body.chart-texture {
    background: url("../img/chart-texture.jpg");
    -webkit-border-radius: 4px 4px 0px 0px;
    border-radius: 4px 4px 0px 0px;
}

/*personal task*/
.task-thumb {
    width: 90px;
    float: left;
    margin-bottom: 5px;
}

.task-thumb img {
    border-radius: 4px;
    -webkit-border-radius: 4px;
}

.task-thumb-details {
    display: inline-block;
    margin: 25px 0 0 10px;
}

.task-progress {
    float: left;
}

.task-thumb-details h1, .task-thumb-details h1 a, .task-progress h1, .task-progress h1 a {
    color: #39b5aa;
    font-size: 18px;
    margin: 0;
    padding: 0;
    font-weight: 400;
}

.task-thumb-details p, .task-progress p {
    padding-top: 5px;
    color: #a4aaba;
}

.personal-task tbody tr td {
    padding: 18px 15px;
    border-color: #eeeff1;
}

.personal-task tbody tr td i {
    font-size: 20px;
    color: #c7cbd4;
}

.personal-task.table-hover > tbody > tr:hover > td, .table-hover > tbody > tr:hover > th {
    background-color: #f7f8fc;
}

.personal-task.table-hover > tbody > tr:hover > td i {
    color: #39b5aa;
}

.personal-task > tbody > tr > td:last-child {
    text-align: right;
}

.task-option {
    float: right;
}

.task-option select {
    height: 35px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    padding: 8px;
    color: #a4abbb;
}

.progress-panel {
    padding-bottom: 5px;
}

/*badge*/
.badge.bg-primary {
    background: #8075c4;
}

.badge.bg-success {
    background: #D4F500;
}

.badge.bg-warning {
    background: #D4F500;
}

.badge.bg-important {
    background: #D4F500;
}

.badge.bg-info {
    background: #41cac0;
}

.badge.bg-inverse {
    background: #2A3542;
}

/*timeline*/
.timeline {
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
    position: relative;
    table-layout: fixed;
    width: 100%;
}

.timeline:before {
    background-color: #C7CBD6;
    bottom: 0px;
    content: "";
    left: 50%;
    position: absolute;
    top: 0;
    width: 2px;
    z-index: 0;
}

h3.timeline-title {
    margin: 0;
    color: #C8CCD7;
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 5px;
    text-transform: uppercase;
}

.t-info {
    color: #C8CCD7;
}

.timeline-item:before, .timeline-item.alt:after {
    content: "";
    display: block;
    width: 50%;
}

.timeline-item {
    display: table-row;
}

.timeline-desk {
    display: table-cell;
    vertical-align: top;
    width: 50%;
}

.timeline-desk h1 {
    font-size: 16px;
    font-weight: 300;
    margin: 0 0 5px;
}

.timeline-desk .panel {
    display: block;
    margin-left: 25px;
    position: relative;
    text-align: left;
    background: #F4F4F4;
}

.timeline-item .timeline-desk .arrow {
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    display: block;
    height: 0;
    left: -7px;
    position: absolute;
    top: 13px;
    width: 0;
}

.timeline-item .timeline-desk .arrow {
    border-right: 8px solid #F4F4F4 !important;
}

.timeline-item.alt .timeline-desk .arrow-alt {
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    display: block;
    height: 0;
    right: -7px;
    position: absolute;
    top: 13px;
    width: 0;
    left: auto;
}

.timeline-item.alt .timeline-desk .arrow-alt {
    border-left: 8px solid #F4F4F4 !important;
}

.timeline .timeline-icon {
    left: -30px;
    position: absolute;
    top: 15px;
}

.timeline .timeline-icon {
    background: #C7CBD6;
    box-shadow: 0 0 0 3px #C7CBD6;
}

.timeline-desk span a {
    text-transform: uppercase;
}

.timeline-desk h1.red, .timeline-desk span a.red {
    color: #EF6F66;
}

.timeline-desk h1.green, .timeline-desk span a.green {
    color: #39B6AE;
}

.timeline-desk h1.blue, .timeline-desk span a.blue {
    color: #56C9F5;
}

.timeline-desk h1.purple, .timeline-desk span a.purple {
    color: #8074C6;
}

.timeline-desk h1.light-green, .timeline-desk span a.light-green {
    color: #A8D76F;
}

.timeline .timeline-icon.red {
    background: #EF6F66;
    box-shadow: 0 0 0 3px #EF6F66;
}

.timeline .timeline-icon.green {
    background: #39B6AE;
    box-shadow: 0 0 0 3px #39B6AE;
}

.timeline .timeline-icon.blue {
    background: #56C9F5;
    box-shadow: 0 0 0 3px #56C9F5;
}

.timeline .timeline-icon.purple {
    background: #8074C6;
    box-shadow: 0 0 0 3px #8074C6;
}

.timeline .timeline-icon.light-green {
    background: #A8D76F;
    box-shadow: 0 0 0 3px #A8D76F;
}

.timeline .timeline-icon {
    border: 3px solid #FFFFFF;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    display: block;
    height: 12px;
    width: 12px;
}

.timeline-item.alt .timeline-icon {
    left: auto;
    right: -32px;
}

.timeline .time-icon:before {
    font-size: 16px;
    margin-top: 5px;
}

.timeline .timeline-date {
    left: -200px;
    position: absolute;
    text-align: right;
    top: 12px;
    width: 150px;
}

.timeline-desk h5 span {
    color: #999999;
    display: block;
    font-size: 12px;
    margin-bottom: 4px;
}

.timeline-item.alt:before {
    display: none;
}

.timeline-item:before, .timeline-item.alt:after {
    content: "";
    display: block;
    width: 50%;
}

.timeline-desk p {
    font-size: 12px;
    margin-bottom: 0;
}

.timeline-desk a {
    color: #EF6F66;
}

.timeline-desk .panel {
    margin-bottom: 5px;
}

.timeline-desk .album {
    margin-top: 20px;
}

.timeline-desk .album a {
    margin-right: 5px;
    float: left;
}

.timeline-desk .notification {
    background: none repeat scroll 0 0 #FFFFFF;
    margin-top: 20px;
    padding: 8px;
}

.timeline-item.alt .panel {
    margin-left: 0;
    margin-right: 25px;
}

.timeline-item.alt .timeline-date {
    left: auto;
    right: -200px;
    text-align: left;
}

.mbot30 {
    margin-bottom: 30px;
}

/*---revenue----*/
.revenue-head {
    background: #ff6c60;
    -webkit-border-radius: 4px 4px 0px 0px;
    border-radius: 4px 4px 0px 0px;
    color: #fff;
    line-height: 50px;
}

.revenue-head span {
    background: #e56155;
    padding: 16px;
    -webkit-border-radius: 4px 0px 0px 0px;
    border-radius: 4px 0px 0px 0px;
}

.revenue-head span i {
    font-size: 18px;
}

.revenue-head h3 {
    display: inline;
    padding: 0 10px;
    font-size: 16px;
    font-weight: 300;
}

.revenue-head span.rev-combo {
    background: #e56155;
    padding: 16px;
    line-height: normal;
    -webkit-border-radius: 0px 4px 0px 0px;
    border-radius: 0px 4px 0px 0px;
}

/*easy pie chart*/
.easy-pie-chart {
    display: inline-block;
    padding: 30px 0;
}

.chart-info, .chart-info .increase, .chart-info .decrease {
    display: inline-block;
}

.chart-info {
    width: 100%;
    margin-bottom: 5px;
}

.chart-position {
    margin-top: 70px;
}

.chart-info span {
    margin: 0 3px;
}

.chart-info .increase {
    background: #ff6c60;
    width: 10px;
    height: 10px;
}

.chart-info .decrease {
    background: #f2f2f2;
    width: 10px;
    height: 10px;
}

.panel-footer.revenue-foot {
    background-color: #e6e7ec;
    -webkit-border-radius: 0px 0px 4px 4px;
    border-radius: 0px 0px 4px 4px;
    border: none;
    padding: 0;
    width: 100%;
    display: inline-block;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {

    /* Safari and Chrome */
    .panel-footer.revenue-foot {
        margin-bottom: -4px;
    }

}

.panel-footer.revenue-foot ul {
    margin: 0;
    padding: 0;
    width: 100%;
    display: inline-flex;
}

.panel-footer.revenue-foot ul li {
    float: left;
    width: 33.33%;
}

.panel-footer.revenue-foot ul li.first a:hover, .panel-footer.revenue-foot ul li.first a {

    /*-webkit-border-radius: 0px 0px 0px 4px;*/
    border-radius: 0px 0px 0px 4px;
}

.panel-footer.revenue-foot ul li.last a:hover, .panel-footer.revenue-foot ul li.last a {
    -webkit-border-radius: 0px 0px 4px 0px;
    border-radius: 0px 0px 4px 0px;
    border-right: none;
}

.panel-footer.revenue-foot ul li a {
    display: inline-block;
    width: 100%;
    padding: 14px 15px;
    text-align: center;
    border-right: 1px solid #d5d8df;
    color: #797979;
}

.panel-footer.revenue-foot ul li a:hover, .panel-footer.revenue-foot ul li.active a {
    background: #fff;
    position: relative;
}

.panel-footer.revenue-foot ul li a i {
    color: #c6cad5;
    display: block;
    font-size: 16px;
}

.panel-footer.revenue-foot ul li a:hover i, .panel-footer.revenue-foot ul li.active a i {
    color: #ff6c60;
    display: block;
    font-size: 16px;
}

/*flatlab carousel model*/
.flat-carousal {
    background: #58c9f3;
    -webkit-border-radius: 4px 4px 0px 0px;
    border-radius: 4px 4px 0px 0px;
    padding: 10px;
    color: #fff;
    position: relative;
}

.flat-carousal h1 {
    text-align: center;
    font-size: 16px;
    margin: 30px 20px;
    line-height: 20px;
    font-weight: 300;
    font-style: italic;
}

a.view-all {
    color: #fff;
    background: rgba(0, 0, 0, 0.1);
    padding: 8px 15px;
    text-align: center;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    margin-bottom: 18px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 12px;
}

ul.ft-link {
    margin: 0;
    padding: 0;
}

ul.ft-link li {
    border-right: 1px solid #E6E7EC;
    display: inline-block;
    line-height: 30px;
    margin: 8px 0;
    text-align: center;
    width: 24%;
}

ul.ft-link li a {
    color: #74829c;
    text-transform: uppercase;
    font-size: 12px;
}

ul.ft-link li a:hover, ul.ft-link li.active a {
    color: #58c9f3;
}

ul.ft-link li:last-child {
    border-right: none;
}

ul.ft-link li a i {
    display: block;
}

#owl-demo .item img {
    display: block;
    width: 100%;
    height: auto;
}

.owl-buttons {
    position: absolute;
    top: 70px;
    width: 100%;
}

.owl-prev, .owl-next {
    position: absolute;
}

.owl-next {
    right: 0;
}

.owl-buttons .owl-prev {
    text-indent: -9999px;
    background: url("../img/left-arrow.png") no-repeat;
    width: 6px;
    height: 10px;
    display: inline-block;
}

.owl-buttons .owl-next {
    text-indent: -9999px;
    background: url("../img/right-arrow.png") no-repeat;
    width: 6px;
    height: 10px;
    display: inline-block;
}

/*product post*/
.post-wrap aside {
    display: table-cell;
    float: none;
    height: 100%;
    padding: 0;
    vertical-align: top;
}

.pro-box {
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
}

.post-info {
    position: relative;
}

.arrow-pro.right:after {
    border-left-color: #FFFFFF;
    border-right-width: 0;
    top: 85px;
    content: " ";
}

.arrow-pro.left:after {
    border-right-color: #FFFFFF;
    border-left-width: 0;
    top: 80px;
    content: " ";
}

.arrow-pro.left {
    left: -8px;
}

.arrow-pro:after {
    border-width: 7px;
    content: "";
}

.arrow-pro, .arrow-pro:after {
    border-color: rgba(0, 0, 0, 0);
    border-style: solid;
    display: block;
    height: 0;
    position: absolute;
    width: 0;
    right: -5px;
}

.post-highlight.yellow {
    background: #f8d347;
    border-radius: 0px 4px 4px 0px;
    -webkit-border-radius: 0px 4px 4px 0px;
}

.post-highlight.terques {
    background: #41cac0;
    border-radius: 4px 0px 0px 04px;
    -webkit-border-radius: 4px 0px 0px 04px;
}

.post-info h1 {
    margin: 0;
    font-size: 18px;
    color: #a19899;
    font-weight: 300;
}

.post-highlight.terques h2 {
    font-size: 16px;
    color: #fff;
    font-style: italic;
    padding: 0 20px;
    line-height: 22px;
    margin: 0;
    font-weight: 300;
}

.post-highlight.terques h2 span, .post-highlight.terques h2 a {
    color: #92faf3;
}

.post-info h1 strong {
    text-transform: uppercase;
    color: #937b7b;
}

.post-info .desk {
    display: inline-block;
}

.post-info .desk  h3 {
    font-size: 16px;
}

.post-info .desk.yellow h3 {
    color: #f8d347;
}

.post-btn {
}

.post-btn a {
    float: left;
    margin-right: 8px;
    font-size: 18px;
    color: #9a9a9a;
}

.post-btn a:hover {
    color: #727272;
}

.pro-thumb {
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 10px solid rgba(255, 255, 255, 0.4);
}

.pro-thumb img {
    text-align: center;
    width: 112px;
    height: 112px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
}

.v-align {
    vertical-align: middle !important;
}

.twite h1 {
    margin: 50px 0;
}

.social-footer {
    display: inline;
    text-align: center;
}

.social-footer ul {
    text-align: center;
    margin: 0;
    padding: 0;
}

.social-footer ul li {
    display: inline-block;
    margin: 0 20px;
}

.social-footer ul li a {
    font-size: 25px;
    color: #ceced0;
}

.social-footer ul li a:hover i.fa-facebook, .social-footer ul li.active a i.fa-facebook {
    color: #486eac;
}

.social-footer ul li a:hover i.fa-twitter, .social-footer ul li.active a i.fa-twitter {
    color: #58c9f3;
}

.social-footer ul li a:hover i.fa-google-plus, .social-footer ul li.active a i.fa-google-plus {
    color: #4a4a4a;
}

.social-footer ul li a:hover i.fa-pinterest, .social-footer ul li.active a i.fa-pinterest {
    color: #d1282d;
}

/*pie chart */
.pie-foot {
    background: #6b6b6b;
    padding: 18px 15px;
    color: #fff;
    border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
    text-align: center;
    font-size: 16px;
    font-weight: 300;
}

/*follower*/
.follower {
    background: #01a89e;
    color: #fff;
    text-align: center;
    border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
}

.follower-foot {
    padding: 8px 5px 5px 5px;
    color: #757575;
    border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
    font-weight: 300;
}

.follower-foot ul {
    padding: 0;
    margin: 0;
}

.follower-foot ul li {
    display: inline-block;
    text-align: center;
    width: 48%;
    line-height: normal;
}

.follower-foot ul li h5 {
    margin: 5px 0 0 0;
}

.follower h4 {
    margin: 0 0 10px 0;
    font-size: 15px;
    font-weight: 300;
}

.follow-ava {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 5px solid #18b2a6;
    display: inline-block;
}

.follower img {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    width: 62px;
    height: 62px;    /*display: inline-block;*/
}

/*weather*/
.weather-bg {
    background: #8175c7;
    border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 300;
}

.weather-bg i {
    font-size: 60px;
    display: block;
}

.weather-bg .degree {
    font-size: 60px;
}

.weather-category {
    padding: 15px 0;
    color: #74829C;
}

.weather-category ul {
    padding: 0;
    margin: 0;
    display: inline-block;
    width: 100%;
}

.weather-category ul li {
    display: inline-block;
    width: 32%;
    text-align: center;
    border-right: 1px solid #e6e6e6;
    display: inline-block;
}

.weather-category ul li h5 {
    margin: 0 0 5px 0;
    text-transform: uppercase;
    font-weight: 300;
}

.weather-category ul li a {
}

.weather-category ul li:last-child {
    border-right: none;
}

/*fontawesome*/
.fontawesome-icon-list h2 {
    margin-top: 0;
    font-size: 20px;
    font-weight: 300;
}

.fontawesome-icon-list .col-sm-4, .fontawesome-icon-list .col-md-3, .fontawesome-icon-list .col-xs-6 {
    margin-bottom: 10px;
}

.fontawesome-icon-list .page-header {
    border-bottom: 1px solid #C9CDD7;
}

.fontawesome-icon-list a {
    color: #797979;
}

.fontawesome-icon-list a:hover {
    color: #35404D;
}

.fontawesome-icon-list i {
    font-size: 16px;
    padding-right: 10px;
}

#web-application, #text-editor, #directional, #video-player, #brand, #medical, #currency {
    margin-top: 10px;
}

/*mail inbox*/
.mail-box {
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
}

.mail-box aside {
    display: table-cell;
    float: none;
    height: 100%;
    padding: 0;
    vertical-align: top;
}

.mail-box .sm-side {
    width: 25%;
    background: #e5e8ef;
    border-radius: 4px 0 0 4px;
    -webkit-border-radius: 4px 0 0 4px;
}

.mail-box .lg-side {
    width: 75%;
    background: #fff;
    border-radius: 0px 4px 4px 0;
    -webkit-border-radius: 0px 4px 4px 0;
}

.mail-box .sm-side .user-head {
    background: #00a8b3;
    border-radius: 4px 0px 0px 0;
    -webkit-border-radius: 4px 0px 0px 0;
    padding: 10px;
    color: #fff;
    min-height: 80px;
}

.user-head .inbox-avatar {
    width: 65px;
    float: left;
}

.user-head .inbox-avatar img {
    border-radius: 4px;
    -webkit-border-radius: 4px;
}

.user-head .user-name {
    display: inline-block;
    margin: 0 0 0 10px;
}

.user-head .user-name h5 {
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 0;
    font-weight: 300;
}

.user-head .user-name h5 a {
    color: #fff;
}

.user-head .user-name span a {
    font-size: 12px;
    color: #87e2e7;
}

a.mail-dropdown {
    background: #80d3d9;
    padding: 3px 5px;
    font-size: 10px;
    color: #01a7b3;
    border-radius: 2px;
    margin-top: 20px;
}

.inbox-body {
    padding: 20px;
}

.btn-compose {
    background: #ff6c60;
    padding: 12px 0;
    text-align: center;
    width: 100%;
    color: #fff;
}

.btn-compose:hover {
    background: #f5675c;
    color: #fff;
}

ul.inbox-nav {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 0;
}

.inbox-divider {
    border-bottom: 1px solid #d5d8df;
}

ul.inbox-nav li {
    display: inline-block;
    line-height: 45px;
    width: 100%;
}

ul.inbox-nav li a {
    color: #6a6a6a;
    line-height: 45px;
    width: 100%;
    display: inline-block;
    padding: 0 20px;
}

ul.inbox-nav li a:hover, ul.inbox-nav li.active a, ul.inbox-nav li a:focus {
    color: #6a6a6a;
    background: #d5d7de;
}

ul.inbox-nav li a i {
    padding-right: 10px;
    font-size: 16px;
    color: #6a6a6a;
}

ul.inbox-nav li a span.label {
    margin-top: 13px;
}

ul.labels-info li h4 {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    color: #5c5c5e;
    font-size: 13px;
    text-transform: uppercase;
}

ul.labels-info li {
    margin: 0;
}

ul.labels-info li a {
    color: #6a6a6a;
    border-radius: 0;
}

ul.labels-info li a:hover, ul.labels-info li a:focus {
    color: #6a6a6a;
    background: #d5d7de;
}

ul.labels-info li a i {
    padding-right: 10px;
}

.nav.nav-pills.nav-stacked.labels-info p {
    margin-bottom: 0;
    padding: 0 22px;
    color: #9d9f9e;
    font-size: 11px;
}

.inbox-head {
    padding: 20px;
    background: #41cac0;
    color: #fff;
    border-radius: 0 4px 0 0;
    -webkit-border-radius: 0 4px 0 0;
    min-height: 80px;
}

.inbox-head  h3 {
    margin: 0;
    display: inline-block;
    padding-top: 6px;
    font-weight: 300;
}

.inbox-head  .sr-input {
    height: 40px;
    border: none;
    box-shadow: none;
    padding: 0 10px;
    float: left;
    border-radius: 4px 0 0 4px;
    color: #8a8a8a;
}

.inbox-head  .sr-btn {
    height: 40px;
    border: none;
    background: #00a6b2;
    color: #fff;
    padding: 0 20px;
    border-radius: 0 4px 4px 0;
    -webkit-border-radius: 0 4px 4px 0;
}

.table-inbox {
    border: 1px solid #d3d3d3;
    margin-bottom: 0;
}

.table-inbox tr td {
    padding: 12px !important;
}

.table-inbox tr td:hover {
    cursor: pointer;
}

.table-inbox tr td .fa-star.inbox-started, .table-inbox tr td .fa-star:hover {
    color: #f78a09;
}

.table-inbox tr td .fa-star {
    color: #d5d5d5;
}

.table-inbox tr.unread td {
    font-weight: 600;
    background: #f7f7f7;
}

ul.inbox-pagination {
    float: right;
}

ul.inbox-pagination li {
    float: left;
}

.mail-option {
    display: inline-block;
    margin-bottom: 10px;
    width: 100%;
}

.mail-option .chk-all, .mail-option .btn-group {
    margin-right: 5px;
}

.mail-option .chk-all, .mail-option .btn-group a.btn {
    border: 1px solid #e7e7e7;
    padding: 5px 10px;
    display: inline-block;
    background: #fcfcfc;
    color: #afafaf;
    border-radius: 3px !important;
    -webkit-border-radius: 3px !important;
}

.inbox-pagination a.np-btn {
    border: 1px solid #e7e7e7;
    padding: 5px 15px;
    display: inline-block;
    background: #fcfcfc;
    color: #afafaf;
    border-radius: 3px !important;
    -webkit-border-radius: 3px !important;
}

.mail-option .chk-all input[type=checkbox] {
    margin-top: 0;
}

.mail-option .btn-group a.all {
    padding: 0;
    border: none;
}

.inbox-pagination a.np-btn {
    margin-left: 5px;
}

.inbox-pagination li span {
    display: inline-block;
    margin-top: 7px;
    margin-right: 5px;
}

.fileinput-button {
    border: 1px solid #e6e6e6;
    background: #eeeeee;
}

.inbox-body .modal .modal-body input, .inbox-body .modal .modal-body textarea {
    border: 1px solid #e6e6e6;
    box-shadow: none;
}

.btn-send, .btn-send:hover {
    background: #00A8B3;
    color: #fff;
}

.btn-send:hover {
    background: #009da7;
}

.modal-header h4.modal-title {
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
}

.modal-body label {
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
}

.heading-inbox h4 {
    font-size: 18px;
    color: #444;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-top: 20px;
}

.sender-info {
    margin-bottom: 20px;
}

.sender-info img {
    width: 30px;
    height: 30px;
}

.sender-dropdown {
    background: #eaeaea;
    padding: 0 3px;
    color: #777;
    font-size: 10px;
}

.view-mail a {
    color: #FF6C60;
}

.attachment-mail {
    margin-top: 30px;
}

.attachment-mail ul {
    width: 100%;
    display: inline-block;
    margin-bottom: 30px;
}

.attachment-mail ul li {
    float: left;
    width: 150px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.attachment-mail ul li img {
    width: 100%;
}

.attachment-mail ul li span {
    float: right;
}

.attachment-mail .file-name {
    float: left;
}

.attachment-mail .links {
    width: 100%;
    display: inline-block;
}

/*404 page*/
.body-404 {
    background: #18d4cb;
    color: #fff;
}

.error-wrapper {
    text-align: center;
    margin-top: 10%;
}

.error-wrapper .icon-404 {
    background: url("../img/404_icon.png") no-repeat;
    width: 289px;
    height: 274px;
    display: inline-block;
    margin-left: 30px;
}

.error-wrapper h1 {
    font-size: 90px;
    font-weight: 300;
    margin: -50px 0 0 0;
}

.error-wrapper h2 {
    font-size: 20px;
    font-weight: 300;
    margin: 0 0 30px 0;
}

.error-wrapper p, .error-wrapper p a {
    font-size: 18px;
    font-weight: 300;
}

.error-wrapper p.page-404 {
    color: #7dfff7;
}

.error-wrapper p.page-404 a, .error-wrapper p.page-500 a, .error-wrapper p.page-404 a:hover, .error-wrapper p.page-500 a:hover {
    color: #fff;
}

/*500 page*/
.body-500 {
    background: #8075c6;
    color: #fff;
}

.error-wrapper p.page-500 {
    color: #afa5f1;
}

.error-wrapper .icon-500 {
    background: url("../img/500_icon.png") no-repeat;
    width: 289px;
    height: 274px;
    display: inline-block;
    margin-left: 55px;
}

/*profile*/
.profile-nav .user-heading {
    background: #ff766c;
    color: #fff;
    border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    padding: 30px;
    text-align: center;
}

.profile-nav .user-heading.round a {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 10px solid rgba(255, 255, 255, 0.3);
    display: inline-block;
}

.profile-nav .user-heading a img {
    width: 112px;
    height: 112px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
}

.profile-nav .user-heading h1 {
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 5px;
}

.profile-nav .user-heading p {
    font-size: 12px;
}

.profile-nav ul {
    margin-top: 1px;
}

.profile-nav ul > li {
    border-bottom: 1px solid #ebeae6;
    margin-top: 0;
    line-height: 30px;
}

.profile-nav ul > li:last-child {
    border-bottom: none;
}

.profile-nav ul > li > a {
    border-radius: 0;
    -webkit-border-radius: 0;
    color: #89817f;
    border-left: 5px solid #fff;
}

.profile-nav ul > li > a:hover, .profile-nav ul > li > a:focus, .profile-nav ul li.active  a {
    background: #f8f7f5 !important;
    border-left: 5px solid #ff766c;
    color: #89817f !important;
}

.profile-nav ul > li:last-child > a:last-child {
    border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
}

.profile-nav ul > li > a > i {
    font-size: 16px;
    padding-right: 10px;
    color: #bcb3aa;
}

.r-activity {
    margin: 6px 0 0;
    font-size: 12px;
}

.p-text-area, .p-text-area:focus {
    border: none;
    font-weight: 300;
    box-shadow: none;
    color: #c3c3c3;
    font-size: 16px;
}

.profile-info .panel-footer {
    background-color: #f8f7f5;
    border-top: 1px solid #e7ebee;
}

.profile-info .panel-footer ul li a {
    color: #7a7a7a;
}

.bio-graph-heading {
    background: #41cac0;
    color: #fff;
    text-align: center;
    font-style: italic;
    padding: 40px 110px;
    border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    font-size: 16px;
    font-weight: 300;
}

.bio-graph-info {
    color: #89817e;
}

.bio-graph-info h1 {
    font-size: 22px;
    font-weight: 300;
    margin: 0 0 20px;
}

.bio-row {
    width: 50%;
    float: left;
    margin-bottom: 10px;
    padding: 0 15px;
}

.bio-row p span {
    width: 100px;
    display: inline-block;
}

.bio-chart, .bio-desk {
    float: left;
}

.bio-chart {
    width: 40%;
}

.bio-desk {
    width: 60%;
}

.bio-desk h4 {
    font-size: 15px;
    font-weight: 400;
}

.bio-desk h4.terques {
    color: #4CC5CD;
}

.bio-desk h4.red {
    color: #e26b7f;
}

.bio-desk h4.green {
    color: #97be4b;
}

.bio-desk h4.purple {
    color: #caa3da;
}

.file-pos {
    margin: 6px 0 10px 0;
}

.profile-activity h5 {
    font-weight: 300;
    margin-top: 0;
    color: #c3c3c3;
}

.summary-head {
    background: #ee7272;
    color: #fff;
    text-align: center;
    border-bottom: 1px solid #ee7272;
}

.summary-head h4 {
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.summary-head p {
    color: rgba(255, 255, 255, 0.6);
}

ul.summary-list {
    display: inline-block;
    padding-left: 0;
    width: 100%;
    margin-bottom: 0;
}

ul.summary-list > li {
    display: inline-block;
    width: 19.5%;
    text-align: center;
}

ul.summary-list > li > a > i {
    display: block;
    font-size: 18px;
    padding-bottom: 5px;
}

ul.summary-list > li > a {
    padding: 10px 0;
    display: inline-block;
    color: #818181;
}

ul.summary-list > li {
    border-right: 1px solid #eaeaea;
}

ul.summary-list > li:last-child {
    border-right: none;
}

.activity {
    width: 100%;
    float: left;
    margin-bottom: 10px;
}

.activity.alt {
    width: 100%;
    float: right;
    margin-bottom: 10px;
}

.activity span {
    float: left;
}

.activity.alt span {
    float: right;
}

.activity span, .activity.alt span {
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    background: #eee;
    text-align: center;
    color: #fff;
    font-size: 16px;
}

.activity.terques span {
    background: #8dd7d6;
}

.activity.terques h4 {
    color: #8dd7d6;
}

.activity.purple span {
    background: #b984dc;
}

.activity.purple h4 {
    color: #b984dc;
}

.activity.blue span {
    background: #90b4e6;
}

.activity.blue h4 {
    color: #90b4e6;
}

.activity.green span {
    background: #aec785;
}

.activity.green h4 {
    color: #aec785;
}

.activity h4 {
    margin-top: 0;
    font-size: 16px;
}

.activity p {
    margin-bottom: 0;
    font-size: 13px;
}

.activity .activity-desk i, .activity.alt .activity-desk i {
    float: left;
    font-size: 18px;
    margin-right: 10px;
    color: #bebebe;
}

.activity .activity-desk {
    margin-left: 70px;
    position: relative;
}

.activity.alt .activity-desk {
    margin-right: 70px;
    position: relative;
}

.activity.alt .activity-desk .panel {
    float: right;
    position: relative;
}

.activity-desk .panel {
    background: #F4F4F4;
    display: inline-block;
}

.activity .activity-desk .arrow {
    border-right: 8px solid #F4F4F4 !important;
}

.activity .activity-desk .arrow {
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    display: block;
    height: 0;
    left: -7px;
    position: absolute;
    top: 13px;
    width: 0;
}

.activity-desk .arrow-alt {
    border-left: 8px solid #F4F4F4 !important;
}

.activity-desk .arrow-alt {
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    display: block;
    height: 0;
    right: -7px;
    position: absolute;
    top: 13px;
    width: 0;
}

.activity-desk .album {
    display: inline-block;
    margin-top: 10px;
}

.activity-desk .album a {
    margin-right: 10px;
}

.activity-desk .album a:last-child {
    margin-right: 0px;
}

/*invoice*/
.invoice-list {
    margin-bottom: 30px;
}

.invoice-list h4 {
    font-weight: 300;
    font-size: 16px;
}

.invoice-block {
    text-align: right;
}

ul.amounts li {
    background: #f5f5f5;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    font-weight: 300;
}

.invoice-btn a {
    font-weight: 300;
    margin: 0 5px;
    font-size: 16px;
}

.corporate-id {
    margin-bottom: 30px;
}

/*panel heading color*/
.panel-primary > .panel-heading.navyblue {
    background-color: #2A3542;
    border-color: #2A3542;
    color: #FFFFFF;
}

/*table*/
.table-advance tr td {
    vertical-align: middle !important;
}

.no-border {
    border-bottom: none;
}

.dataTables_length, .dataTables_filter {
    padding: 15px;
}

.dataTables_info {
    padding: 0 15px;
}

.dataTables_filter {
    float: right;
}

.dataTables_length select {
    width: 65px;
    padding: 5px 8px;
}

.dataTables_length label, .dataTables_filter label {
    font-weight: 300;
}

.dataTables_filter label {
    width: 100%;
}

.dataTables_filter label input {
    width: 78%;
}

.border-top {
    border-top: 1px solid #ddd;
}

.dataTables_paginate.paging_bootstrap.pagination li {
    float: left;
    margin: 0 1px;
    border: 1px solid #ddd;
    border-radius: 3px;
    -webkit-border-radius: 3px;
}

.dataTables_paginate.paging_bootstrap.pagination li.disabled a {
    color: #c7c7c7;
}

.dataTables_paginate.paging_bootstrap.pagination li a {
    color: #797979;
    padding: 5px 10px;
    display: inline-block;
}

.dataTables_paginate.paging_bootstrap.pagination li:hover a, .dataTables_paginate.paging_bootstrap.pagination li.active a {
    color: #797979;
    background: #eee;
    border-radius: 3px;
    -webkit-border-radius: 3px;
}

.dataTables_paginate.paging_bootstrap.pagination {
    float: right;
    margin-top: -5px;
    margin-bottom: 15px;
}

.dataTable tr:last-child {
    border-bottom: 1px solid #ddd;
}

/*calendar*/
.has-toolbar.fc {
    margin-top: 50px;
}

.fc-header-title {
    display: inline-block;
    margin-top: -50px;
    vertical-align: top;
}

.fc-view {
    margin-top: -50px;
    overflow: hidden;
    width: 100%;
}

.fc-state-default, .fc-state-default .fc-button-inner {
    background: #F3F3F3 !important;
    border-color: #DDDDDD;
    border-style: none solid;
    color: #646464;
}

.fc-state-active, .fc-state-active .fc-button-inner, .fc-state-hover, .fc-state-hover .fc-button-inner {
    background: #FF6C60 !important;
    color: #fff !important;
}

.fc-event-skin {
    background-color: #6883a3 !important;
    border-color: #6883a3 !important;
    color: #FFFFFF !important;
}

.fc-grid th {
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #F3F3F3 !important;
}

.fc-header-title h2 {
    font-size: 20px !important;
    color: #C8CCD7;
    font-weight: 300;
}

.external-event {
    cursor: move;
    display: inline-block !important;
    margin-bottom: 6px !important;
    margin-right: 6px !important;
    padding: 8px;
}

#external-events p input[type="checkbox"] {
    margin: 0;
}

.drg-event-title {
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.fc-content .fc-event {
    border-radius: 4px;
    webkit-border-radius: 4px;
    padding: 4px 6px;
}

.fc-corner-left {
    border-radius: 4px 0 0 4px;
    -webkit-border-radius: 4px 0 0 4px;
}

.fc-corner-right {
    border-radius: 0 4px 4px 0;
    -webkit-border-radius: 0 4px 4px 0;
}

.drp-rmv {
    padding-top: 10px;
    margin-top: 10px;
}

/*button*/
.btn-row {
    margin-bottom: 10px;
}

/*tabs*/
.tab-head {
    background: #7087a3;
    display: inline-block;
    width: 100%;
    margin-top: 60px;
}

.tab-container {
    margin-top: 10px;
}

.tab-head .nav-tabs > li > a {
    border-radius: 0;
    margin-right: 1px;
    color: #fff;
}

.tab-head .nav-tabs > li.active > a, .tab-head .nav-tabs > li > a:hover, .tab-head .nav-tabs > li.active > a:hover, .tab-head .nav-tabs > li.active > a:focus {
    background-color: #f1f2f7;
    border-color: #f1f2f7;
    color: #797979;
}

/*general page*/
.progress-xs {
    height: 8px;
}

.progress-sm {
    height: 12px;
}

.panel-heading .nav {
    border: medium none;
    font-size: 13px;
    margin: -10px -15px -11px;
}

.tab-bg-dark-navy-blue {
    background: #7087A3;
    border-radius: 5px 5px 0 0;
    -webkit-border-radius: 5px 5px 0 0;
    border-bottom: none;
}

.panel-heading .nav > li > a, .panel-heading .nav > li.active > a, .panel-heading .nav > li.active > a:hover, .panel-heading .nav > li.active > a:focus {
    border-width: 0;
    border-radius: 0;
}

.panel-heading .nav > li > a {
    color: #fff;
}

.panel-heading .nav > li.active > a, .panel-heading .nav > li > a:hover {
    color: #47596f;
    background: #fff;
}

.panel-heading .nav > li:first-child.active > a, .panel-heading .nav > li:first-child > a:hover {
    border-radius: 4px 0 0 0;
    -webkit-border-radius: 4px 0 0 0;
}

.tab-right {
    height: 38px;
}

.panel-heading.tab-right .nav > li:first-child.active > a, .tab-right.panel-heading .nav > li:first-child > a:hover {
    border-radius: 0;
    -webkit-border-radius: 0;
}

.panel-heading.tab-right .nav > li:last-child.active > a, .tab-right.panel-heading .nav > li:last-child > a:hover {
    border-radius: 0 4px 0 0;
    -webkit-border-radius: 0 4px 0 0;
}

.panel-heading.tab-right .nav-tabs > li > a {
    margin-left: 1px;
    margin-right: 0px;
}

.m-bot20 {
    margin-bottom: 20px;
}

.m-bot-none {
    margin-bottom: 0;
}

.wht-color {
    color: #fff;
}

.close-sm {
    font-size: 14px;
}

/*carousel*/
.carousel-indicators li {
    background: rgba(0, 0, 0, 0.2);
    border: none;
    transition: background-color 0.25s ease 0s;
    -moz-transition: background-color 0.25s ease 0s;
    -webkit-transition: background-color 0.25s ease 0s;
}

.carousel-indicators .active {
    background: #ff6c60;
    height: 10px;
    margin: 1px;
    width: 10px;
}

.carousel-indicators.out {
    bottom: -5px;
}

.carousel-indicators.out {
    bottom: -5px;
}

.carousel-control {
    color: #999999;
    text-shadow: none;
    width: 45px;
}

.carousel-control i {
    display: inline-block;
    height: 25px;
    left: 50%;
    margin-left: -10px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
    width: 20px;
    z-index: 5;
}

.carousel-control.left, .carousel-control.right {
    background: none;
    filter: none;
}

.carousel-control:hover, .carousel-control:focus {
    color: #CCCCCC;
    opacity: 0.9;
    text-decoration: none;
}

.carousel-inner h3 {
    font-weight: 300;
    font-size: 16px;
    margin: 0;
}

.carousel-inner {
    margin-bottom: 15px;
}

/*gritter*/
.gritter-close {
    left: auto !important;
    right: 3px !important;
}

/*form*/
.sm-input {
    width: 175px;
}

.form-horizontal.tasi-form .form-group {
    border-bottom: 1px solid #eff2f7;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.form-horizontal.tasi-form .form-group:last-child {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.form-horizontal.tasi-form .form-group .help-block {
    margin-bottom: 0;
}

.round-input {
    border-radius: 500px;
    -webkit-border-radius: 500px;
}

.m-bot15 {
    margin-bottom: 15px;
}

.form-horizontal.tasi-form .checkbox-inline > input {
    margin-top: 1px;
    border: none;
}

.iconic-input {
    position: relative;
}

.iconic-input i {
    color: #CCCCCC;
    display: block;
    font-size: 16px;
    height: 16px;
    margin: 8px 5px 8px 10px;
    position: absolute;
    text-align: center;
    width: 16px;
}

.iconic-input input {
    padding-left: 30px !important;
}

.iconic-input.right input {
    padding-left: 10px !important;
    padding-right: 30px !important;
}

.iconic-input.right i {
    float: right;
    right: 5px;
}

input.spinner[type="text"], input.spinner[type="password"], input.spinner[type="datetime"], input.spinner[type="datetime-local"], input.spinner[type="date"], input.spinner[type="month"], input.spinner[type="time"], input.spinner[type="week"], input.spinner[type="number"], input.spinner[type="email"], input.spinner[type="url"], input.spinner[type="search"], input.spinner[type="tel"], input.spinner[type="color"] {
    background: url("../img/input-spinner.gif") right no-repeat !important;
}

/*form validation*/
.cmxform .form-group label.error {
    display: inline;
    margin: 5px 0;
    color: #B94A48;
    font-weight: 400;
}

input:focus:invalid:focus, textarea:focus:invalid:focus, select:focus:invalid:focus, .cmxform .form-group input.error, .cmxform .form-group textarea.error {
    border-color: #B94A48 !important;
}

#signupForm label.error {
    display: inline;
    margin: 5px 0px;
    width: auto;
    color: #B94A48;
}

.checkbox, .checkbox:hover, .checkbox:focus {
    border: none;
}

/*slider*/
table.sliders tr td {
    padding: 30px 0;
    border: none;
}

.slider {
    margin-top: 3px;
}

.slider-info {
    padding-top: 10px;
}

.sliders .ui-widget-header {
    background: #22bacf !important;
    border-radius: 15px !important;
    -webkit-border-radius: 15px !important;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
    border-bottom-right-radius: 0 !important;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
    border-bottom-left-radius: 0 !important;
}

.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
    border-top-right-radius: 0 !important;
}

.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
    border-top-left-radius: 0 !important;
}

#eq span {
    height: 120px;
    float: left;
    margin: 15px
}

.ui-widget-content {
    background: #f0f2f7 !important;
    border: none !important;
    border-radius: 15px !important;
    -webkit-border-radius: 15px !important;
}

.ui-slider-horizontal {
    height: 8px !important;
}

.ui-slider-horizontal .ui-slider-handle {
    top: -0.57em !important;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
    background: #fff !important;
    border: 3px solid #22bacf !important;
    border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
}

.ui-slider-vertical {
    width: 8px !important;
}

.ui-slider-vertical .ui-slider-handle {
    left: -0.5em !important;
}

.ui-slider .ui-slider-handle {
    cursor: default;
    height: 1.6em;
    position: absolute;
    width: 1.6em;
    z-index: 2;
}

.bound-s {
    width: 90px;
    margin-bottom: 15px;
}

/*----switch ----*/
.has-switch {
    border-radius: 30px;
    -webkit-border-radius: 30px;
    display: inline-block;
    cursor: pointer;
    line-height: 1.231;
    overflow: hidden;
    position: relative;
    text-align: left;
    width: 80px;
    -webkit-mask: url('../img/mask.png') 0 0 no-repeat;
    mask: url('../img/mask.png') 0 0 no-repeat;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.has-switch.deactivate {
    opacity: 0.5;
    filter: alpha(opacity=50);
    cursor: default !important;
}

.has-switch.deactivate label, .has-switch.deactivate span {
    cursor: default !important;
}

.has-switch > div {
    width: 162%;
    position: relative;
    top: 0;
}

.has-switch > div.switch-animate {
    -webkit-transition: left 0.25s ease-out;
    -moz-transition: left 0.25s ease-out;
    -o-transition: left 0.25s ease-out;
    transition: left 0.25s ease-out;
    -webkit-backface-visibility: hidden;
}

.has-switch > div.switch-off {
    left: -63%;
}

.has-switch > div.switch-off label {
    background-color: #7f8c9a;
    border-color: #bdc3c7;
    -webkit-box-shadow: -1px 0 0 rgba(255, 255, 255, 0.5);
    -moz-box-shadow: -1px 0 0 rgba(255, 255, 255, 0.5);
    box-shadow: -1px 0 0 rgba(255, 255, 255, 0.5);
}

.has-switch > div.switch-on {
    left: 0%;
}

.has-switch > div.switch-on label {
    background-color: #41cac0;
}

.has-switch input[type=checkbox] {
    display: none;
}

.has-switch span {
    cursor: pointer;
    font-size: 14.994px;
    font-weight: 700;
    float: left;
    height: 29px;
    line-height: 19px;
    margin: 0;
    padding-bottom: 6px;
    padding-top: 5px;
    position: relative;
    text-align: center;
    width: 50%;
    z-index: 1;    /*-webkit-box-sizing: border-box;*/

    /*-moz-box-sizing: border-box;*/
    box-sizing: border-box;
    -webkit-transition: 0.25s ease-out;
    -moz-transition: 0.25s ease-out;
    -o-transition: 0.25s ease-out;
    transition: 0.25s ease-out;
    -webkit-backface-visibility: hidden;
}

.has-switch span.switch-left {
    border-radius: 30px 0 0 30px;
    background-color: #2A3542;
    color: #41cac0;
    border-left: 1px solid transparent;
}

.has-switch span.switch-right {
    border-radius: 0 30px 30px 0;
    background-color: #bdc3c7;
    color: #ffffff;
    text-indent: 7px;
}

.has-switch span.switch-right [class*="fui-"] {
    text-indent: 0;
}

.has-switch label {
    border: 4px solid #2A3542;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    float: left;
    height: 29px;
    margin: 0 -21px 0 -14px;
    padding: 0;
    position: relative;
    vertical-align: middle;
    width: 29px;
    z-index: 100;
    -webkit-transition: 0.25s ease-out;
    -moz-transition: 0.25s ease-out;
    -o-transition: 0.25s ease-out;
    transition: 0.25s ease-out;
    -webkit-backface-visibility: hidden;
}

.switch-square {
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -webkit-mask: url('../img/mask.png') 0 0 no-repeat;
    mask: url('../img/mask.png') 0 0 no-repeat;
}

.switch-square > div.switch-off label {
    border-color: #7f8c9a;
    border-radius: 6px 0 0 6px;
}

.switch-square span.switch-left {
    border-radius: 6px 0 0 6px;
}

.switch-square span.switch-left [class*="fui-"] {
    text-indent: -10px;
}

.switch-square span.switch-right {
    border-radius: 0 6px 6px 0;
}

.switch-square span.switch-right [class*="fui-"] {
    text-indent: 5px;
}

.switch-square label {
    border-radius: 0 6px 6px 0;
    border-color: #41cac0;
}

/*tag input*/
.tagsinput {
    border: 1px solid #e3e6ed;
    border-radius: 6px;
    height: 100px;
    padding: 6px 1px 1px 6px;
    overflow-y: auto;
    text-align: left;
}

.tagsinput .tag {
    border-radius: 4px;
    background-color: #41cac0;
    color: #ffffff;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    line-height: 15px;
    padding: 6px 13px 8px 19px;
    position: relative;
    vertical-align: middle;
    display: inline-block;
    zoom: 1;
    *display: inline;
    -webkit-transition: 0.14s linear;
    -moz-transition: 0.14s linear;
    -o-transition: 0.14s linear;
    transition: 0.14s linear;
    -webkit-backface-visibility: hidden;
}

.tagsinput .tag:hover {
    background-color: #39b1a8;
    color: #ffffff;
    padding-left: 12px;
    padding-right: 20px;
}

.tagsinput .tag:hover .tagsinput-remove-link {
    color: #ffffff;
    opacity: 1;
    display: block\9;
}

.tagsinput input {
    background: transparent;
    border: none;
    color: #34495e;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    margin: 0px;
    padding: 0 0 0 5px;
    outline: 0;
    margin-right: 5px;
    margin-bottom: 5px;
    width: 12px;
}

.tagsinput-remove-link {
    bottom: 0;
    color: #ffffff;
    cursor: pointer;
    font-size: 12px;
    opacity: 0;
    padding: 7px 7px 5px 0;
    position: absolute;
    right: 0;
    text-align: right;
    text-decoration: none;
    top: 0;
    width: 100%;
    z-index: 2;
    display: none\9;
}

.tagsinput-remove-link:before {
    color: #ffffff;
    content: "\f00d";
    font-family: "FontAwesome";
}

.tagsinput-add-container {
    vertical-align: middle;
    display: inline-block;
    zoom: 1;
    *display: inline;
}

.tagsinput-add {
    background-color: #d6dbdf;
    border-radius: 3px;
    color: #ffffff;
    cursor: pointer;
    margin-bottom: 5px;
    padding: 6px 9px;
    display: inline-block;
    zoom: 1;
    *display: inline;
    -webkit-transition: 0.25s;
    -moz-transition: 0.25s;
    -o-transition: 0.25s;
    transition: 0.25s;
    -webkit-backface-visibility: hidden;
}

.tagsinput-add:hover {
    background-color: #3bb8af;
}

.tagsinput-add:before {
    content: "\f067";
    font-family: "FontAwesome";
}

.tags_clear {
    clear: both;
    width: 100%;
    height: 0px;
}

/*checkbox & radio style*/
.checkboxes label, .radios label {
    display: block;
    cursor: pointer;
    line-height: 20px;
    padding-bottom: 7px;
    font-weight: 300;
}

.radios {
    padding-top: 18px;
}

.label_check input, .label_radio input {
    margin-right: 5px;
}

.has-js .label_check, .has-js .label_radio {
    padding-left: 34px;
}

.has-js .label_radio {
    background: url(../img/checkbox/radio-off.png) no-repeat;
}

.has-js .label_check {
    background: url(../img/checkbox/check-off.png) no-repeat;
}

.has-js label.c_on {
    background: url(../img/checkbox/check-on.png) no-repeat;
}

.has-js label.r_on {
    background: url(../img/checkbox/radio-on.png) no-repeat;
}

.has-js .label_check input, .has-js .label_radio input {
    position: absolute;
    left: -9999px;
}

/*date picker*/
.add-on {
    float: right;
    margin-top: -37px;
    padding: 3px;
    text-align: center;
}

.add-on .btn {
    padding: 12px 9px;
}

.daterangepicker .ranges .range_inputs > div:nth-child(2) {
    margin-bottom: 10px;
    padding-left: 0px;
}

.daterangepicker .ranges label {
    padding-bottom: 0;
    padding-top: 8px;
}

.daterangepicker td.active, .daterangepicker td.active:hover, .datepicker td.active:hover, .datepicker td.active:hover:hover, .datepicker td.active:active, .datepicker td.active:hover:active, .datepicker td.active.active, .datepicker td.active.active:hover, .datepicker td.active.disabled, .datepicker td.active.disabled:hover, .datepicker td.active[disabled], .datepicker td.active[disabled]:hover, .datepicker td span.active:hover, .datepicker td span.active:active, .datepicker td span.active.active, .datepicker td span.active.disabled, .datepicker td span.active[disabled], .datetimepicker table tr td.active:hover, .datetimepicker table tr td.active:hover:hover, .datetimepicker table tr td.active.disabled:hover, .datetimepicker table tr td.active.disabled:hover:hover, .datetimepicker table tr td.active:active, .datetimepicker table tr td.active:hover:active, .datetimepicker table tr td.active.disabled:active, .datetimepicker table tr td.active.disabled:hover:active, .datetimepicker table tr td.active.active, .datetimepicker table tr td.active.active:hover, .datetimepicker table tr td.active.disabled.active, .datetimepicker table tr td.active.disabled.active:hover, .datetimepicker table tr td.active.disabled, .datetimepicker table tr td.active.disabled:hover, .datetimepicker table tr td.active.disabled.disabled, .datetimepicker table tr td.active.disabled.disabled:hover, .datetimepicker table tr td.active[disabled], .datetimepicker table tr td.active[disabled]:hover, .datetimepicker table tr td.active.disabled[disabled], .datetimepicker table tr td.active.disabled[disabled]:hover {
    background: #41CAC0;
}

.datetimepicker table tr td span.active:active, .datetimepicker table tr td span.active:hover:active, .datetimepicker table tr td span.active.disabled:active, .datetimepicker table tr td span.active.disabled:hover:active, .datetimepicker table tr td span.active.active, .datetimepicker table tr td span.active:hover.active, .datetimepicker table tr td span.active.disabled.active, .datetimepicker table tr td span.active.disabled:hover.active {
    background: #41CAC0;
}

.daterangepicker .calendar th, .daterangepicker .calendar td {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    text-align: center;
    white-space: nowrap;
}

.daterangepicker td.active, .daterangepicker td.active:hover, .datepicker td.active, .datepicker td.active:hover, .datepicker td span.active {
    text-shadow: none;
}

.datepicker th.switch {
    width: 125px;
}

.datepicker td span {
    height: 40px;
    line-height: 40px;
}

.bootstrap-timepicker table td input {
    border: 1px solid #ccc;
    border-radius: 3px;
    -webkit-border-radius: 3px;
}

/*ck editor*/
.cke_top, .cke_bottom {
    background: #F5F5F5 !important;
    background: -moz-linear-gradient(center top, #F5F5F5, #F5F5F5) repeat scroll 0 0 #F5F5F5 !important;
    background: -webkit-linear-gradient(center top, #F5F5F5, #F5F5F5) repeat scroll 0 0 #F5F5F5 !important;
    background: -o-linear-gradient(center top, #F5F5F5, #F5F5F5) repeat scroll 0 0 #F5F5F5 !important;
    box-shadow: none;
    padding: 6px 8px 2px;
}

.cke_top {
    border-bottom: 1px solid #cccccc !important;
}

.cke_chrome {
    display: block;
    padding: 0;
}

/*form wizard*/
.stepy-tab {
    text-align: center;
}

.stepy-tab ul {
    display: inline-block;
}

.stepy-tab ul li {
    float: left;
}

.step legend {
    border: none;
}

.button-back {
    float: left;
}

.button-next, .finish {
    float: right;
}

.button-back, .button-next, .finish {
    cursor: pointer;
    text-decoration: none;
}

.step {
    clear: left;
}

.step label {
    display: block;
}

.stepy-titles li {
    color: #757575;
    cursor: pointer;
    float: left;
    margin: 10px 15px;
}

.stepy-titles li span {
    display: block;
}

.stepy-titles li.current-step div {
    color: #fff;
    cursor: auto;
    background: #A9D86E;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.stepy-titles li div {
    font-size: 16px;
    font-weight: 300;
    background: #eee;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

/*widget*/
.user-heading.alt {
    display: inline-block;
    width: 100%;
    text-align: left;
}

.alt.green-bg {
    background: #aec785;
}

.profile-nav.alt.green-border ul > li > a:hover, .profile-nav.alt.green-border ul > li > a:focus, .profile-nav.alt.green-border ul li.active a {
    border-left: 5px solid #aec785;
}

.user-heading.alt a {
    float: left;
    margin-right: 15px;
    margin-left: -10px;
    display: inline-block;
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    -webkit-border-radius: 50%;
}

.user-heading.alt a img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
}

.twt-feed {
    border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    color: #FFFFFF;
    padding: 10px;
    position: relative;
    text-align: center;
}

.twt-feed.blue-bg {
    background: #58C9F3;
}

.twt-feed h1 {
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 5px;
}

.twt-feed a {
    border: 8px solid #fff;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    display: inline-block;
    margin-bottom: -55px;
}

.twt-feed a img {
    height: 112px;
    width: 112px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
}

.twt-category {
    display: inline-block;
    margin-bottom: 11px;
    margin-top: 55px;
    width: 100%;
}

.twt-category ul li {
    color: #89817f;
    font-size: 13px;
}

.twt-category h5 {
    font-size: 20px;
    font-weight: 300;
}

.twt-write .t-text-area {
    border: 1px solid #eeeeee;
    border-radius: 0;
}

.twt-write {
    margin-bottom: 10px;
}

.twt-footer {
    padding: 10px 15px;
}

.btn-space {
    padding-left: 11.6%;
    padding-right: 11%;
}

.p-head {
    color: #f77b6f;
    font-weight: 400;
    font-size: 14px;
}

.cmt-head {
    font-weight: 400;
    font-size: 13px;
}

.p-thumb img {
    width: 50px;
    height: 50px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
}

.tasi-tab .media-body p {

    /*color: #b8bac6;*/
}

.pull-right.chat-features {
    margin-top: 10px;
}

/*Timeline chat*/
.chat-form {
    margin-top: 25px;
    clear: both;
}

.chat-form .input-cont {
    margin-bottom: 10px;
}

.chat-form .input-cont input {
    margin-bottom: 0px;
}

.chat-form .input-cont input {
    border: 1px solid #d3d3d3 !important;
    margin-top: 0;
    min-height: 45px;
}

.chat-form .input-cont input {
    background-color: #fff !important;
}

.chat-features a {
    margin-left: 10px;
}

.chat-features a i {
    color: #d0d0d0;
}

.timeline-messages:before {
    background: rgba(0, 0, 0, 0.1);
    bottom: 0;
    top: 0;
    width: 2px;
}

.timeline-messages:before, .msg-time-chat:before, .msg-time-chat .text:before {
    content: "";
    left: 60px;
    position: absolute;
    top: -2px;
}

.timeline-messages, .msg-time-chat, .timeline-messages .msg-in, .timeline-messages .msg-out {
    position: relative;
}

.timeline-messages .msg-in  .arrow {

    /*border-right: 8px solid #F4F4F4 !important;*/
}

.timeline-messages .msg-in .arrow {
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    display: block;
    height: 0;
    left: -8px;
    position: absolute;
    top: 13px;
    width: 0;
}

.timeline-messages .msg-out  .arrow {

    /*border-right: 8px solid #41cac0 !important;*/
}

.timeline-messages .msg-out .arrow {
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    display: block;
    height: 0;
    left: -8px;
    position: absolute;
    top: 13px;
    width: 0;
}

.msg-time-chat:first-child:before {
    margin-top: 16px;
}

.msg-time-chat:before {
    background: #CCCCCC;
    border: 2px solid #FAFAFA;
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    height: 14px;
    margin: 23px 0 0 -6px;
    width: 14px;
}

.msg-time-chat:hover:before {
    background: #41cac0;
}

.msg-time-chat:first-child {
    padding-top: 0;
}

.message-img {
    float: left;
    margin-right: 30px;
    overflow: hidden;
}

.message-img img {
    display: block;
    height: 44px;
    width: 44px;
}

.message-body {
    margin-left: 80px;
}

.msg-time-chat .msg-in .text {
    border: 1px solid #e3e6ed;
    padding: 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
}

.msg-time-chat .msg-out .text {
    border: 1px solid #e3e6ed;
    padding: 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
}

.msg-time-chat p {
    margin: 0;
}

.msg-time-chat .attribution {
    font-size: 11px;
    margin: 0px 0 5px;
}

.msg-time-chat {
    overflow: hidden;
    padding: 8px 0;
}

.msg-in a, .msg-in a:hover {
    color: #b64c4c;
    text-decoration: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    margin-right: 10px;
    font-weight: 400;
    font-size: 13px;
}

.msg-out a, .msg-out a:hover {
    color: #288f98;
    text-decoration: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    margin-right: 10px;
    font-weight: 400;
    font-size: 13px;
}

/*custom select*/
span.customSelect {
    font-size: 12px;
    background-color: #ffffff;
    padding: 10px;
    border: 1px solid #EAEAEA;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    color: #A4AABA;
}

span.customSelect.changed {
    background-color: #fff;
}

.customSelectInner {
    background: url(../img/customSelect-arrow.gif) no-repeat center right;
}

/*boxed page */
.boxed-page {
    background-color: #ccc !important;
}

.boxed-page .container {
    background: #2A3542;
    padding-left: 0;
    padding-right: 0;
}

.boxed-page .container #sidebar {
    position: inherit;
}

.boxed-page .container .header .container {
    background: #fff;
}

.boxed-page .container aside {
    float: left;
}

.boxed-page .container .wrapper {
    background: #F1F2F7;
    min-height: 900px;
}

/*collapsible*/
.tools a {
    color: #797979;
    font-size: 12px;
    margin-left: 4px;
}

/* google maps */
.gmaps {
    height: 300px;
    width: 100%;
}

/* star rating */
.rating {
    unicode-bidi: bidi-override;
    direction: rtl;
    font-size: 30px;
}

.rating span.star, .rating span.star {
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    display: inline-block;
}

.rating span.star:hover, .rating span.star:hover {
    cursor: pointer;
}

.rating span.star:before, .rating span.star:before {
    content: "\f006";
    padding-right: 5px;
    color: #BEC3C7;
}

.rating span.star:hover:before, .rating span.star:hover:before, .rating span.star:hover ~ span.star:before, .rating span.star:hover ~ span.star:before {
    content: "\f005";
    color: #41CAC0;
}

/*search page*/
.classic-search {
    margin-bottom: 30px;
}

.classic-search h4 {
    margin-bottom: 3px;
    font-weight: 300;
    font-size: 16px;
}

.classic-search h4 a {
    color: #314558;
}

.classic-search h4 a:hover {
    text-decoration: underline;
}

/*ckEditor*/
#editor-container {
    width: 100%;
    margin: 10px auto 0;
}

#header-editor {
    overflow: hidden;
    padding: 0 0 30px;
    border-bottom: 1px solid #eaeaea;
    position: relative;
}

#headerLeft, #headerRight {
    width: 49%;
    overflow: hidden;
}

#headerLeft {
    float: left;
    padding: 10px 1px 1px;
}

#headerLeft h2, #headerLeft h3 {
    margin: 0;
    overflow: hidden;
    font-weight: normal;
    font-family: 'Open Sans', sans-serif;
}

#headerLeft h2 {
    font-size: 2.6em;
    line-height: 1.1em;
    text-transform: capitalize;
    color: #314558;
    margin-bottom: 20px;
}

#headerLeft h3 {
    font-size: 1.5em;
    line-height: 1.1em;
    margin: .2em 0 0;
    color: #757575;
}

#headerRight {
    float: right;
    padding: 1px;
}

#headerRight p {
    line-height: 1.8em;
    text-align: justify;
    margin: 0;
}

#headerRight p + p {
    margin-top: 20px;
}

#headerRight > div {
    padding: 20px;
    margin: 0 0 0 30px;
    font-size: 1.1em;
    color: #757575;
}

#columns {
    color: #757575;
    overflow: hidden;
    padding: 20px 0;
}

#columns h3 {
    color: #314558;
}

#columns > div {
    float: left;
    width: 33.3%;
}

#columns #column1 > div {
    margin-left: 1px;
}

#columns #column3 > div {
    margin-right: 1px;
}

#columns > div > div {
    margin: 0px 10px;
    padding: 10px 20px;
}

#columns blockquote {
    margin-left: 15px;
}

#taglist {
    display: inline-block;
    margin-left: 20px;
    font-weight: bold;
    margin: 0 0 0 20px;
}

.cke_editable.cke_editable_inline.cke_focus {
    background: #fcfcfc;
    border: 1px solid #eaeaea;
    cursor: text;
    outline: medium none;
}

/*advanced table*/
.adv-table table tr td {
    padding: 10px;
}

.adv-table table.display thead th {
    border-bottom: 1px solid #DDDDDD;
    padding: 10px;
}

tr.odd.gradeA td.sorting_1, tr.odd td.sorting_1, tr.even.gradeA td.sorting_1 {
    background: none;
}

td.details {
    background-color: #eee;
}

td.details table tr td, .dataTable tr:last-child {
    border: none;
}

.adv-table table.display tr.odd.gradeA {
    background-color: #fbfbfb;
}

.adv-table table.display tr.even.gradeA {
    background-color: #FFFFFF;
}

.adv-table .dataTables_filter label input {
    float: right;
    margin-left: 10px;
    width: 78%;
}

.adv-table .dataTables_filter label {
    line-height: 33px;
    width: 100%;
}

.adv-table .dataTables_length select {
    display: inline-block;
    margin: 0 10px 0 0;
    padding: 5px 8px;
    width: 65px;
}

.adv-table .dataTables_info, .dataTables_paginate {
    padding: 15px 0;
}

.adv-table .dataTables_length, .adv-table .dataTables_filter {
    padding: 15px 0;
}

.cke_chrome {
    border: none !important;
}

.editable-table .dataTables_filter {
    width: 80%;
}

tr.odd.gradeX td.sorting_1, tr.even.gradeX td.sorting_1, table.display tr.even.gradeX, table.display tr.gradeX, tr.even.gradeU td.sorting_1, tr.even td.sorting_1, table.display tr.even.gradeC, table.display tr.gradeC, tr.odd.gradeC td.sorting_1, table.display tr.even.gradeU, table.display tr.gradeU, tr.odd.gradeU td.sorting_1 {
    background: none !important;
}

/*flot chart*/
.flot-chart .chart, .flot-chart .pie, .flot-chart .bars {
    height: 300px;
}

/*xchart*/
.demo-xchart {
    height: 400px;
    width: 100%;
}

/*Horizontal menu*/
.full-width #main-content {
    margin-left: 0;
}

.horizontal-menu {
    margin-left: 50px;
    float: left;
}

.horizontal-menu .navbar-nav > li > a {
    padding-bottom: 20px;
    padding-top: 20px;
}

.full-width .navbar-header {
    width: 100%;
}

.full-width .nav > li > a:hover, .full-width .nav  li.active a, .full-width .nav li.dropdown a:hover, .full-width .nav li.dropdown.open a:focus, .full-width .nav .open > a, .full-width  .nav .open > a:hover, .full-width  .nav .open > a:focus {
    background-color: #F77B6F;
    text-decoration: none;
    color: #fff;
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
}

.full-width .dropdown-menu {
    box-shadow: none;
}

.full-width .dropdown-menu > li > a {
    padding: 10px 20px;
    font-size: 13px;
}

/*advanced form*/
.form-body {
    padding: 20px;
}

/*multiselect*/
.ms-container .ms-selectable li.ms-hover, .ms-container .ms-selection li.ms-hover {
    background-color: #2A3542;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: none;
}

.ms-container .ms-list, .ms-container .ms-list.ms-focus {
    box-shadow: none !important;
}

.ms-container .ms-list.ms-focus {
    border: 1px solid #2A3542;
}

.ms-selectable .search-input, .ms-selection .search-input {
    margin-bottom: 10px;
}

/*spinner*/
.spinner-buttons.btn-group-vertical .btn {
    height: 17px;
    margin: 0;
    padding-left: 6px;
    padding-right: 6px;
    text-align: center;
    width: 22px;
}

.spinner-buttons.btn-group-vertical .btn i {
    margin-top: -3px;
}

.spinner-buttons.btn-group-vertical .btn:first-child {
    border-radius: 0 4px 0 0 !important;
    -webkit-border-radius: 0 4px 0 0 !important;
}

.spinner-buttons.btn-group-vertical .btn:last-child {
    border-radius: 0 0 4px !important;
    -webkit-border-radius: 0 0 4px !important;
}

/**/
.wysihtml5-toolbar .btn-default {
    background: #fff;
    color: #757575;
}

/*todolist*/
#sortable {
    list-style-type: none;
    margin: 0 0 20px 0;
    padding: 0;
    width: 100%;
}

#sortable li {
    padding-left: 3em;
    font-size: 12px;
}

#sortable li i {
    position: absolute;
    left: 6px;
    padding: 4px 10px 0 10px;
    cursor: pointer;
}

#sortable li input[type=checkbox] {
    margin-top: 0;
}

.ui-sortable > li {
    padding: 15px 0 15px 35px !important;
    position: relative;
    background: #f5f6f8;
    margin-bottom: 2px;
    border-bottom: none !important;
}

.ui-sortable li.list-primary {
    border-left: 3px solid #41CAC0;
}

.ui-sortable li.list-success {
    border-left: 3px solid #78CD51;
}

.ui-sortable li.list-danger {
    border-left: 3px solid #FF6C60;
}

.ui-sortable li.list-warning {
    border-left: 3px solid #F1C500;
}

.ui-sortable li.list-info {
    border-left: 3px solid #58C9F3;
}

.ui-sortable li.list-inverse {
    border-left: 3px solid #BEC3C7;
}

/*lock screen*/
.lock-screen {
    background: #02bac6 url("../img/lock-bg.jpg");
    background-size: cover;
    background-repeat: repeat;
}

.lock-wrapper {
    margin: 10% auto;
    max-width: 330px;
}

.lock-box {
    background: rgba(255, 255, 255, .3);
    padding: 20px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    position: relative;
}

.lock-wrapper img {
    position: absolute;
    left: 40%;
    top: -40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 5px solid #fff;
}

.lock-wrapper h1 {
    text-align: center;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    padding: 20px 0 0 0;
}

.lock-wrapper .locked {
    margin-bottom: 20px;
    display: inline-block;
    color: #026f7a;
}

.btn-lock, .btn-lock:hover {
    background: #02b5c2;
    color: #fff;
}

.lock-input {
    width: 83%;
    border: none;
    float: left;
    margin-right: 3px;
}

#time {
    width: 100%;
    color: #fff;
    font-size: 60px;
    margin-bottom: 80px;
    display: inline-block;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}

/*language*/
.language {
    margin-top: 4px;
}

.language .dropdown-menu {
    border: 1px solid #eee;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.176) !important;
}

.language .dropdown-menu li a {
    border-bottom: 1px solid #eee;
    padding: 10px;
}

.language .dropdown-menu li:last-child a {
    border-bottom: none;
}

.language .dropdown-menu li a {
    font-size: 13px;
}

/*product list*/
.prod-cat li a {
    border-bottom: 1px dashed #d9d9d9;
}

.prod-cat li a {
    color: #3b3b3b;
}

.prod-cat li ul {
    margin-left: 30px;
}

.prod-cat li ul li a {
    border-bottom: none;
}

.prod-cat li ul li a:hover, .prod-cat li ul li a:focus, .prod-cat li ul li.active a, .prod-cat li a:hover, .prod-cat li a:focus, .prod-cat li a.active {
    background: none;
    color: #ff7261;
}

.pro-lab {
    margin-right: 20px;
    font-weight: normal;
}

.pro-sort {
    padding-right: 20px;
    float: left;
}

.pro-page-list {
    margin: 5px 0 0 0;
}

.product-list img {
    width: 100%;
    border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
}

.product-list .pro-img-box {
    position: relative;
}

.adtocart {
    background: #fc5959;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    color: #fff;
    display: inline-block;
    text-align: center;
    border: 3px solid #fff;
    left: 45%;
    bottom: -25px;
    position: absolute;
}

.adtocart i {
    color: #fff;
    font-size: 25px;
    line-height: 42px;
}

.pro-title {
    color: #5A5A5A;
    display: inline-block;
    margin-top: 20px;
    font-size: 16px;
}

.product-list .price {
    color: #fc5959;
    font-size: 15px;
}

.pro-img-details {
    margin-left: -15px;
}

.pro-img-details img {
    width: 100%;
}

.pro-d-title {
    font-size: 16px;
    margin-top: 0;
}

.product_meta {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 10px 0;
    margin: 15px 0;
}

.product_meta span {
    display: block;
    margin-bottom: 10px;
}

.product_meta a, .pro-price {
    color: #fc5959;
}

.pro-price, .amount-old {
    font-size: 18px;
    padding: 0 10px;
}

.amount-old {
    text-decoration: line-through;
}

.quantity {
    width: 120px;
}

.pro-img-list {
    margin: 10px 0 0 -15px;
    width: 100%;
    display: inline-block;
}

.pro-img-list a {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
}

.pro-d-head {
    font-size: 18px;
    font-weight: 300;
}

/*footer*/
.site-footer {
    background: #5b6e84;
    color: #fff;
    padding: 10px 0;
}

.go-top {
    margin-right: 1%;
    float: right;
    background: rgba(255, 255, 255, .5);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
}

.go-top i {
    color: #2A3542;
}

.site-min-height {
    min-height: 900px;
}

.always-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
}

/*pricing table*/
.feature-head {
    padding: 10px 0 50px;
}

.feature-head h1 {
    font-size: 25px;
    font-weight: normal;
}

.pricing-table {
    background: #fff;
    text-align: center;
    padding: 0 0 25px 0;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
}

.pricing-table.most-popular {
    top: -20px;
    position: relative;
}

.most-popular {
    background: #f77b6f;
    color: #fff;
}

.most-popular h1 {
    font-size: 25px !important;
    padding-bottom: 10px;
    padding-top: 17px !important;
}

.most-popular h2 {
    background: #d76b61 !important;
    margin-top: 20px !important;
}

.most-popular ul li {
    border-bottom: 1px dotted #d76b61 !important;
}

.most-popular .price-actions .btn {
    background: #d76b61 !important;
    margin: 10px 0;
    cursor: pointer;
}

.pricing-table .price-actions .btn {
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    background: #acacac;
    color: #fff;
    border: none;
    box-shadow: none;
    text-shadow: none;
    padding: 10px 20px;
    cursor: pointer;
}

.pricing-head h1 {
    font-size: 18px;
    font-weight: 300;
    padding-top: 15px;
}

.pricing-head h2 {
    padding: 30px 0;
    background: #777777;
    color: #fff;
    font-size: 50px;
    font-weight: 100;
}

.pricing-table ul {
    margin: 15px 0;
    padding: 0;
}

.pricing-table ul li {
    border-bottom: 1px dotted #CCCCCC;
    margin: 0 2em;
    padding: 1em 0;
    text-align: center;
    font-weight: 300;
}

.pricing-head span.note {
    display: inline;
    font-size: 25px;
    line-height: 0.8em;
    position: relative;
    top: -18px;
}

.pricing-quotation, .team-info {
    background: #EEEEEE;
    padding: 20px 20px 35px 20px;
    margin-bottom: 100px;
    display: inline-block;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
}

.pricing-quotation h3, .team-info h3 {
    font-weight: 300;
}

.pricing-quotation p, .team-info p {
    margin-bottom: 0px;
}

.pricing-plan, .team-info-wrap {
    position: relative;
}

.pricing-quotation:before, .team-info:before {
    background-color: #EEEEEE;
    border-color: #EEEEEE;
    border-image: none;
    border-right: 1px solid #EEEEEE;
    border-style: none;
    top: -7px;
    content: "";
    display: block;
    height: 14px;
    left: 48%;
    position: absolute;
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 15px;
}

/*dragable portlet*/
.sortable .panel .panel-heading {
    cursor: move;
}

/*faq*/
.vertical-menu li a {
    background: #fff;
    width: 100%;
    float: left;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    color: #797979;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.vertical-menu li a:hover, .vertical-menu li a:focus, .vertical-menu li.active a {
    background: #2A3542;
    width: 100%;
    float: left;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    color: #fff;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

/*fb time line*/
.cover-photo {
    position: relative;
}

.fb-timeline-img img {
    width: 100%;
    border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
}

.profile-thumb img {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    margin-top: -90px;
    border: 3px solid #fff;
}

.profile-thumb {
    float: left;
    position: relative;
}

.fb-user-mail {
    margin: 10px 0 0 20px;
    display: inline-block;
}

.fb-name {
    bottom: 5px;
    left: 175px;
    position: absolute;
}

.fb-name h2 a {
    color: #FFFFFF;
    text-rendering: optimizelegibility;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
    font-size: 25px;
}

.fb-user-thumb {
    float: left;
    width: 70px;
    margin-right: 15px;
}

.fb-user-thumb img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
}

.fb-user-details h3 {
    margin: 15px 0 0;
    font-size: 18px;
    font-weight: 300;
}

.fb-user-details p {
    color: #c3c3c3;
}

.fb-user-status {
    padding: 10px 0;
    line-height: 20px;
}

.fb-time-action {
    padding: 15px 0;
}

.fb-border {
    border-top: 1px solid #ebeef5;
}

.fb-time-action span, .fb-time-action a {
    margin-right: 5px;
}

.fb-time-action a {
    color: #2972a1;
}

.fb-time-action a:hover {
    text-decoration: underline;
}

.fb-time-action span {
    color: #5a5a5a;
}

.fb-status-container, .fb-comments li {
    margin: 0 -15px 0 -15px;
    padding: 0 15px;
}

.fb-gray-bg {
    background: #f6f6f6;
}

.fb-comments li {
    border-top: 1px solid #ebeef5;
    padding: 15px;
}

.fb-comments .cmt-thumb {
    width: 50px;
    float: left;
    margin-right: 15px;
}

.fb-comments .cmt-thumb img {
    width: 50px;
}

.fb-comments .cmt-details {
    padding-top: 5px;
}

.fb-comments .cmt-details a {
    font-size: 14px;
    font-weight: bold;
}

.fb-comments .cmt-details a.like-link {
    font-size: 12px;
    font-weight: normal;
}

.cmt-form {
    display: inline-block;
    width: 90%;
}

.cmt-form textarea {
    height: 50px;
    line-height: 35px;
}

.fb-timeliner h2 {
    background: #828283;
    color: #fff;
    margin-top: 0;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    font-weight: 300;
}

.fb-timeliner ul {
    margin-left: 15px;
    margin-bottom: 20px;
}

.fb-timeliner ul li {
    margin-bottom: 3px;
}

.fb-timeliner ul li a {
    color: #999797;
    border-left: 4px solid #d3d7dd;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    display: block;
}

.fb-timeliner ul li a:hover {
    color: #999797;
    border-left: 4px solid #b1b1b1;
    padding-left: 10px;
}

.fb-timeliner ul li.active a {
    color: #7a7a7a;
    border-left: 4px solid #7a7a7a;
    padding-left: 10px;
}

.recent-highlight {
    background: #FF6C60 !important;
}

/*chat room*/
.chat-room {
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
    position: relative;
}

.chat-room aside {
    display: table-cell;
    float: none;
    height: 100%;
    padding: 0;
    vertical-align: top;
}

.chat-room .left-side {
    width: 25%;
    background: #e5e8ef;
    border-radius: 4px 0 0 4px;
    -webkit-border-radius: 4px 0 0 4px;
}

.chat-room .mid-side {
    width: 50%;
    background: #fff;
    border-right: 1px solid #e5e8ef;
}

.chat-room .right-side {
    width: 25%;
    background: #fff;
    border-radius: 0 4px 4px 0;
    -webkit-border-radius: 0 4px 4px 0;
}

.chat-room .left-side .user-head {
    background: #00A8B3;
    border-radius: 4px 0 0;
    -webkit-border-radius: 4px 0 0;
    color: #FFFFFF;
    min-height: 70px;
    padding: 15px;
}

.chat-room .right-side .user-head {
    background: #00A8B3;
    border-radius: 0 4px 0 0;
    -webkit-border-radius: 0 4px 0 0;
    color: #FFFFFF;
    min-height: 70px;
    padding: 10px;
    border-left: 1px solid #00A8B3;
    margin-left: -1px;
    position: relative;
}

.chat-room .user-head i {
    float: left;
    font-size: 40px;
    margin-right: 10px;
}

.chat-room .user-head h3 {
    margin: 6px 0 0 0;
    font-weight: 100;
    letter-spacing: 1px;
}

.chat-room-head {
    background: #41CAC0;
    color: #FFFFFF;
    min-height: 70px;
    padding: 15px;
}

.chat-room-head h3 {
    margin: 5px 0 0;
    font-weight: 100;
    letter-spacing: 1px;
    display: inline-block;
}

.chat-room-head .search-btn {
    width: 20px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    box-shadow: none;
    background: #01a6b2 url("../img/chat-search.png") no-repeat 12px 9px;
    padding: 0 5px 0 35px;
    margin-top: 2px;
    border: none;
    color: #fff;
}

.chat-room-head .search-btn:focus {
    width: 180px;
    box-shadow: none;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;    /*background: #01a6b2;*/
    font-weight: 300;
    color: #fff;
}

.chat-room-head .search-btn:focus::-moz-placeholder {
    color: #fff;
}

ul.chat-list li a {
    color: #6a6a6a;
    display: block;
    padding: 15px;
    font-weight: 300;
}

ul.chat-list li a:hover, ul.chat-list li.active a {
    color: #00a9b4;
    background: #f2f4f7;
}

ul.chat-list li h4 {
    padding: 17px 15px;
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #D5D7DE;
}

ul.chat-list li h4 i {
    padding-right: 5px;
}

ul.chat-list li a span {
    padding-left: 10px;
}

ul.chat-list li a i.fa-times {
    color: #9fa3b0;
}

ul.chat-list li.active {
    color: #00a9b4;
    background: #f2f4f7;
}

ul.chat-list {
    border-bottom: 1px solid #d5d7de;
}

ul.chat-user {
    margin-bottom: 200px;
}

ul.chat-user li {
    border-bottom: none;
}

ul.chat-user li a:hover {
    background: none;
    color: #6a6a6a;
}

.chat-room .left-side footer {
    background: #d5d7de;
    border-radius: 0 0 0 4px;    /*-webkit-border-radius: 0 0 0 4px;*/
    padding: 15px;
    height: 70px;
    width: 25%;
    position: absolute;
    bottom: 0;
}

.chat-room .mid-side footer {
    background: #f6f6f6;
    padding: 15px;
    height: 70px;
    width: 50%;
    position: absolute;
    bottom: 0;
    border-right: 1px solid #E5E8EF;
}

.chat-room .right-side footer {
    background: #fff;
    padding: 15px;
    height: 70px;
    width: 25%;
    position: absolute;
    bottom: 0;
    border-top: 1px solid #E5E8EF;
    border-radius: 0 0 4px 0;
    -webkit-border-radius: 0 0 4px 0;
}

.chat-room .left-side footer .chat-avatar img {
    width: 40px;
    height: 40px;
    border: 2px solid #fff;
    float: left;
}

.chat-room .left-side footer .user-status {
    float: left;
    margin: 10px;
}

.chat-room .left-side footer .user-status i {
    padding-right: 5px;
}

.chat-room .left-side footer a.chat-dropdown {
    background: #96979a;
    border-radius: 2px;
    color: #fff;
    font-size: 10px;
    margin-top: 10px;
    padding: 3px 5px;
}

.room-desk {
    display: inline-block;
    margin-bottom: 30px;
    width: 100%;
    padding: 15px;
}

.room-desk h4 {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 16px;
    margin: 5px 0 0 0;
}

.room-box {
    border: 1px solid #e7e7e7;
    background: #f7f8fa;
    padding: 10px;
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
}

.room-box h5 {
    margin: 0 0 5px 0;
    font-weight: 300;
    font-size: 16px;
}

.room-box h5 a {
    color: #00a9b4;
}

.chat-tools {
    float: right;
    padding: 3px;
    width: 40px;
    height: 35px;
    line-height: 30px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    text-align: center;
    margin-top: 6px;
    margin-left: 10px;
}

.chat-tools i {
    font-size: 16px !important;
    float: none !important;
    margin-right: 0 !important;
    color: #fff;
}

.btn-key {
    background: #4ac9c0;
}

.btn-key:hover {
    background: #44bab2;
}

.invite-row {
    background: #E5E8EF;
    padding: 6px 10px;
    display: inline-block;
    width: 100%;
}

.invite-row h4 {
    font-size: 16px;
    font-weight: 300;
}

ul.chat-available-user {
    padding: 10px;
}

ul.chat-available-user li {
    margin-bottom: 15px;
}

ul.chat-available-user li a {
    color: #6a6a6a;
}

ul.chat-available-user li i {
    padding-right: 5px;
    font-size: 10px;
}

.group-rom {
    width: 100%;
    float: left;
    border-bottom: 1px solid #eaebee;
}

.group-rom .first-part, .group-rom .second-part, .group-rom .third-part {
    float: left;
    padding: 15px;
}

.group-rom .first-part {
    width: 25%;
}

.group-rom .first-part.odd {
    background: #f7f8fa;
    color: #6a6a6a;
    font-weight: 600;
}

.group-rom .second-part {
    width: 60%;
}

.group-rom .third-part {
    width: 15%;
    color: #d4d3d3;
}

a.guest-on {
    color: #6a6a6a;
    margin-top: 8px;
    display: inline-block;
}

a.guest-on i {
    background: #40cabe;
    color: #fff;
    padding: 4px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    margin-right: 5px;
}

.chat-txt {
    float: left;
    width: 70%;
    margin-right: 5px;
}

.lobby {
    padding: 0 !important;
}

/*right side bar*/
.sb-slidebar {
    background-color: #134759 !important;
    margin-top: 60px;
    color: #aeb2b7;
}

.sb-slidebar .side-title {
    padding: 10px 15px;
    text-transform: uppercase;
    color: #FF6C60;
}

.sb-toggle-right {
    cursor: pointer;
    margin-top: 12px;
}

.sb-toggle-right i {
    font-size: 20px;
}

.quick-chat-list {
    margin-bottom: 20px;
}

.quick-chat-list:last-child {

    /*padding: 0;*/
    padding-bottom: 50px;
}

.quick-chat-list li {
    padding: 10px 15px;
    border-bottom: 1px dashed #35404d;
    cursor: pointer;
}

.quick-chat-list li:hover {
    background: #35404d;
}

.quick-chat-list li .media-object {
    border-radius: 50%;
    -webkit-border-radius: 50%;
}

.media .media-status {
    display: inline-block;
    margin-top: 10px;
    position: absolute;
    right: 10px;
}

.media .media-body small {
    display: block;
}

.quick-chat-list .media .media-object {
    width: 40px;
    height: 40px;
}

/*-------*/
.p-task {
    margin-bottom: 20px;
    padding-bottom: 50px;
}

.p-task li {
    padding: 10px 15px 15px 15px;
    border-bottom: 1px dashed #35404d;
}

.p-task li:last-child {
    padding: 10px 15px 15px 15px;
    border-bottom: none;
}

.p-task li a {
    color: #aeb2b7;
}

.p-task.tasks-bar .task-info .percent {
    display: inline-block;
    float: right;
    font-size: 12px;
    margin-top: -15px;
}

.p-task .progress {
    height: 10px;
    margin-top: 5px;
    margin-bottom: 0;
}

.p-task .external a {
    color: #40cabe;
}

.p-task .external a:hover {
    color: #FF6C60;
}

/*Directory Styles*/
.directory-list, .directory-info-row .social-links {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.directory-list li {
    border-left: 3px solid #f1f2f7;
    display: table-cell;
    width: 1%;
}

.directory-list li a {
    display: block;
    padding: 8px 0;
    text-align: center;
    text-transform: uppercase;
    background: #fff;
    color: #7A7676;
    -moz-transition: all 0.2s ease-out 0s;
    -webkit-transition: all 0.2s ease-out 0s;
    transition: all 0.2s ease-out 0s;
    text-decoration: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.directory-list li a:hover, .directory-info-row .social-links li a:hover {
    background: #ff6c60;
    color: #fff;
}

.directory-info-row {
    display: inline-block;
    width: 100%;
    margin-top: 20px;
}

.directory-info-row .social-links {
    display: inline-block;
    margin-bottom: 10px;
}

.directory-info-row .social-links li {
    display: inline-block;
}

.directory-info-row .social-links li a {
    background: #EFF0F4;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    color: #7A7676;
}

.directory-info-row h4, .directory-info-row a {
    color: #424F63;
}

.directory-info-row .thumb {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    height: auto;
    width: 175px;
    margin-right: 10px;
}

/*mega-menu*/
.mega-menu {
    float: left;
    margin: 5px 0 0 50px;
}

.mega-menu .navbar-nav {
    height: 55px;
}

/*.wide-full {*/

/*width: 98%;*/

/*}*/

.mega-menu ul li a {
    margin-bottom: 15px;
    display: inline-block;
    color: #323232;
    font-size: 13px;
}

.mega-menu ul li a:hover, .mega-menu ul li a:focus {
    color: #ff6c60;
    background-color: #fff;
}

.mega-menu .nav .open>a, .mega-menu .nav .open>a:hover, .mega-menu .nav .open>a:focus {
    background-color: #fff;
}

.mega-menu ul li a i {
    padding-right: 5px;
    width: 20px;
}

.mega-menu ul li.dropdown a:hover {
    background: none;
}

.mega-nav #main-content {
    margin-left: 0px;
}

.mega-nav .title {
    color: #323232;
    font-size: 14px;
    text-transform: none;
    font-weight: bold;
}

.mega-nav .d-title, .mega-nav .d-desk {
    display: block;
}

.mega-nav .d-desk {
    color: #aaa;
    font-size: 11px;
}

.custom-nav-img .desk {
    line-height: 25px;
    font-size: 13px;
}

.custom-nav-img {
    position: static;
}

.yamm-content {
    position: relative;
}

.mega-bg {
    background-image: url("../img/mega-menu/corner_image.jpg");
    background-repeat: no-repeat;
    background-position: bottom right;
    position: absolute;
    right: 0;
    bottom: -5px;
    width: 300px;
    height: 130px;
}

.icon-img {
    float: left;
    margin-right: 10px;
}

.icon-desk {
    display: inline-block;
}

.mega-menu .dropdown-menu {
    box-shadow: none;
    border-radius: 0;
    border-color: #f1f2f7;
}

/*project list*/
.p-table th, .p-table td {
    vertical-align: middle !important;
}

.p-team img {
    width: 35px;
    height: 35px;
    border-radius: 4px;
}

.p-progress .progress {
    margin-bottom: 5px;
}

.p-name a {
    font-size: 14px;    /*font-weight:bold;*/
}

.bold {
    font-weight: bold;
}

.p-details .bio-row p span {
    width: auto;
}

.p-details .bio-row p span.bold {
    width: 100px;
}

.p-team a {
    display: inline-block;
}

.project-heading {
    font-size: 20px;
}

.mtop20 {
    margin-top: 20px;
}

.p-files li a {
    margin-bottom: 10px;
    color: #949494;
    display: block;
}

.p-files li i {
    padding-right: 10px;
    width: 20px;
}

.p-tag-list li {
    display: inline-block;
    margin: 0 5px 7px 0;
}

.p-tag-list li a {
    display: inline-block;
    border: 1px solid #ddd;
    padding: 3px 10px;
    color: #797979;
    border-radius: 4px;
    font-size: 12px;
}

.p-tag-list li a:hover {
    background: #ff6c60;
    border-color: #ff6c60;
    color: #fff;
}

.m-thumb img {
    width: 100%;
    border: 1px solid #ddd;
}

/*-----modal style---*/
.modal.top-modal-with-space .modal-dialog {
    margin: 20px auto;
}

.modal.fade.left-modal-with-space .modal-dialog {
    position: absolute;
    left: 20px;
}

.modal.fade.right-modal-with-space .modal-dialog {
    position: absolute;
    right: 20px;
}

/*---*/
.modal.top-modal-without-space .modal-dialog {
    margin: 0px auto;
}

.modal.fade.left-modal-without-space .modal-dialog {
    position: absolute;
    left: 0px;
}

.modal.fade.right-modal-without-space .modal-dialog {
    position: absolute;
    right: 0px;
}

/*---*/
.modal.fade.modal-dialog-center {
    height: 100%;    /* top: auto; */
}

.modal.fade.modal-dialog-center .modal-dialog {
    display: table;
    height: 100%;
}

.modal.fade.modal-dialog-center .modal-content-wrap {
    display: table-cell;
    vertical-align: middle;
}

/*-----*/
.modal.fade.full-width-modal-left .modal-dialog .modal-content, .modal.fade.full-width-modal-right .modal-dialog .modal-content, .modal.fade.full-width-modal-right .modal-dialog .modal-content-wrap, .modal.fade.full-width-modal-left .modal-dialog .modal-content-wrap {
    height: 100%;
}

.modal.fade.full-width-modal-right .modal-dialog {
    height: 100%;
    position: absolute;
    right: 0px;
    margin: 0;
}

.modal.fade.full-width-modal-left .modal-dialog {
    height: 100%;
    position: absolute;
    left: 0px;
    margin: 0;
}

.full-width-modal-right .modal-header, .full-width-modal-right .modal-content, .full-width-modal-left .modal-header, .full-width-modal-left .modal-content {
    border-radius: 0;
}

/*header theme color*/
.theme-h-color img {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.theme-h-color p {
    line-height: 30px;
    margin-bottom: 15px;
}

header.green-bg a.logo, header.dark-bg a.logo, header.purple-bg a.logo, header.blue-bg a.logo {
    color: #fff;
}

header.green-bg {
    background: #88ae59;
    border-bottom: 1px solid #88ae59;
}

header.dark-bg {
    background: #2a3542;
    border-bottom: 1px solid #3b4a5c;
}

header.purple-bg {
    background: #8175c7;
    border-bottom: 1px solid #8175c7;
}

header.blue-bg {
    background: #16aad8;
    border-bottom: 1px solid #16aad8;
}

header.green-bg .sidebar-toggle-box .fa-bars, header.green-bg .sb-toggle-right, header.dark-bg .sidebar-toggle-box .fa-bars, header.dark-bg .sb-toggle-right, header.purple-bg .sidebar-toggle-box .fa-bars, header.purple-bg .sb-toggle-right, header.blue-bg .sidebar-toggle-box .fa-bars, header.blue-bg .sb-toggle-right {
    color: #fff;
}

header.green-bg  ul.top-menu > li > a, header.green-bg .search {
    border-color: #9dc867 !important;
    color: #fff;
}

header.dark-bg  ul.top-menu > li > a, header.dark-bg .search {
    border-color: #3a495b !important;
    color: #fff;
}

header.purple-bg  ul.top-menu > li > a, header.purple-bg .search {
    border-color: #9587e6 !important;
    color: #fff;
}

header.blue-bg  ul.top-menu > li > a, header.blue-bg .search {
    border-color: #18b7e8 !important;
    color: #fff;
}

header.green-bg ul.top-menu > li > a:hover .username, header.green-bg ul.top-menu > li > a:focus .username, header.dark-bg ul.top-menu > li > a:hover .username, header.dark-bg ul.top-menu > li > a:focus .username, header.purple-bg ul.top-menu > li > a:hover .username, header.purple-bg ul.top-menu > li > a:focus .username, header.blue-bg ul.top-menu > li > a:hover .username, header.blue-bg ul.top-menu > li > a:focus .username {
    color: #323232 !important;
}

header.green-bg .search, header.dark-bg .search, header.purple-bg .search, header.blue-bg .search {
    color: #fff !important;
}

header.green-bg  .top-nav .username, header.dark-bg  .top-nav .username, header.purple-bg  .top-nav .username, header.blue-bg  .top-nav .username {
    color: #fff;
}

header.green-bg .top-nav .nav .caret, header.dark-bg .top-nav .nav .caret, header.purple-bg .top-nav .nav .caret, header.blue-bg .top-nav .nav .caret {
    border-bottom-color: #fff;
    border-top-color: #fff;
}

header.green-bg ul.top-menu > li > a:hover .caret, header.green-bg ul.top-menu > li > a:focus .caret, header.dark-bg ul.top-menu > li > a:hover .caret, header.dark-bg ul.top-menu > li > a:focus .caret, header.purple-bg ul.top-menu > li > a:hover .caret, header.purple-bg ul.top-menu > li > a:focus .caret, header.blue-bg ul.top-menu > li > a:hover .caret, header.blue-bg ul.top-menu > li > a:focus .caret {
    border-bottom-color: #000;
    border-top-color: #000;
}

header.green-bg .search, header.dark-bg .search, header.purple-bg .search, header.blue-bg .search {
    margin-top: 6px;
    width: 20px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    border: 1px solid #fff;
    box-shadow: none;
    background: url("../img/search-icon-white.png") no-repeat 10px 8px;
    padding: 0 5px 0 35px;
    color: #000;
}

header.green-bg .search:focus, header.dark-bg .search:focus, header.purple-bg .search:focus, header.blue-bg .search:focus {
    margin-top: 5px;
    width: 180px;
    border: 1px solid #eaeaea;
    box-shadow: none;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    color: #c8c8c8;
    font-weight: 300;
}

/*toaster*/
.radio-list  label, .checkbox-list label {
    display: inline-block;
    width: 100%;
    font-weight: normal;
}

.radio-list .radio, .radio-list .radio span, .radio-list .radio input {
    width: 18px;
    height: 18px;
    float: left;
    margin: 0 10px 0 0;
}

.checkbox-list .checker span {
    float: left;
    margin-right: 10px;
}

.toastr-pad  input, .toastr-row label {
    margin-bottom: 10px;
}

#toast-container > div, #toast-container >:hover {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.box-wrap {
    height: 400px;
    overflow: hidden;
}

.w-list li {
    line-height: 20px;
    margin-bottom: 16px;
}

/*summernote editor*/
.note-editor .note-toolbar {
    background-color: #fff;
    border-bottom: 1px solid #e2e2e4;
    border-radius: 4px 4px 0 0;
}

.note-editor {
    position: relative;
    border: 1px solid #e2e2e4;
    border-radius: 4px;
}

.note-editor .note-statusbar .note-resizebar {
    border-top: 1px solid #e2e2e4;
}

.note-editor .note-statusbar {
    background: #fff;
    border-radius: 4px;
}

.note-editor .note-editable ul {
    padding-left: 40px;
}

.note-editor .note-editable ul li {
    list-style: disc;
}

/*anoop css start here*/

/*.logo_section
{
    background: #134759 none repeat scroll 0 0;
height: auto;
padding:20px 28px 30px 19px;
position: absolute;
width: 100%;
}*/
.header {
    padding: 0;
}

.logo_section {
    float: left;
    height: 101%;
    left: 0;
    position: absolute;
    top: 0;
    width: 210px;
    background: #134759;
    padding: 20px 23px;
    display: inline-block;
    z-index: 999;
}

.header_right {
    float: none;
    height: 100%;
    padding-left: 210px;
    position: relative;
    width: 100%;
}

.header {
    padding-left: 0;
}

.searchicon {
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    z-index: -1;
}

html body ul.sidebar-menu li a .message_icon {
    background: #D4F500 none repeat scroll 0 0;
    border-radius: 3px;
    color: #ffffff;
    display: inline-block;
    float: right;
    font-size: 13px;
    padding: 2px 6px;
}

html body ul.sidebar-menu li a .minus_icon {
    background-position: -13px -2px;
}

/*tabel section start here*/
.adv-table table.display thead.heading_table th {
    border-bottom: 1px solid #dddddd;
    padding: 14px 7px;
}

/*.clock_icon
{
        display: inline-block;
margin: 0 6px 0 0px;
position: relative;
top: -5px;
}*/
.date_info {
    display: block;
    min-height: 20px;
    line-height: 20px;
}

.active_date {
    margin-left: 6px;
    font-size: 13px;
}

.right_active {
    color: #D4F500;
}

.hide {
    display: none;
}

.panel-body {
    background: #ffffff;
}

.panel-body-wrapper {
    padding: 20px 15px 20px 15px;
    margin-bottom: 30px;
}

.zuletzt_text {
    font-size: 16px;
    color: #797979;
    background: #fff;
    margin-bottom: 2px;
    padding: 15px;
    font-weight: 100;
}

/*tabel section end here*/
.task-thumb-details h1 a {
    color: #D4F500;
    font-size: 18px;
    text-transform: capitalize;
    margin: 0 0 5px
}

.task-thumb-details p {
    font-size: 13px;
    color: #a4aabb;
}

.year_month {
    font-size: 13px;
    font-weight: 400;
    color: #797979;
    float: right;
    padding-top: 48px;
    display: inline-block;
}

.algerechnet {
    background: #bfc2cd;
    color: #fff;
    text-transform: capitalize;
}

.last_td {
    background: #f1f0f0 !IMPORTANT;
}

.last_list_icon {
    text-align: center;
}

table.table thead .sorting {
    background: rgba(0, 0, 0, 0) url("../img/sort_both.png") no-repeat scroll right center;
}

.center_text {
    text-align: center;
}

.personal-task.table-hover > tbody > tr:hover > td, .table-hover > tbody > tr:hover > th {
    background-color: #fff;
}

.personal-task.table-hover > tbody > tr:hover > td i, .personal-task.table-hover > tbody > tr:hover > td {
    color: #D4F500;
}

.details {
    padding-top: 24px !important;
}

.details td, html body .details tr td.sorting_1 {
    background: #eeeeee !important;
}

.pxs_row {
    margin: 0;
}

.pox_content {
    margin-top: 97px;
}

.disbale {
    background: #f1f0f0 !important;
    color: #aaaaaa;
}

.disbale .algerechnet {
    display: block;
    width: 76px;
    margin: 0 auto;
}

.disbale .btn_list {
    display: none;
}

.closed_heading {
    color: #797979;
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 13px;
    border-bottom: 1px solid #c9cdd7;
    margin-bottom: 13px;
    text-transform: capitalize;
}

.project_kunde_search h3 {
    color: #797979 !important;
    font-size: 13px !important;
    font-weight: 300 !important;
    margin: 0 0 6px;
    font-family: 'Open Sans', sans-serif;
}

.project_kunde_search h3 i {
    display: inline-block;
    margin-right: 6px;
}

.project_kunde_search select, .project_kunde_search  input {
    color: #797979;
    font-size: 13px;
    font-weight: 400;
    background: #fff;
    height: 29px;
    padding: 2px 10px;
    border: 1px solid #e2e2e4;
    border-radius: 5px;
    width: 100%;
}

.project_kunde_search select, .pxs-sl-sd .detail_list li select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    background: #fff url('../img/down.jpg') no-repeat 96% center;
    cursor: pointer;
}

.wrapper_dagsec {
    margin: 18px 0 0;
    padding: 0;
}

.wrapper_dagsec .sortable {
    padding: 0;
}

/*.project_kunde_search select
{
    margin:0 0 19px;
}*/
.pxs-sl-sd .bill_erstellen {
    font-size: 16px;
    color: #797979;
    font-weight: 400;
    padding: 13px;
    border-bottom: 1px solid #eff2f7;
    background: #fff;
    margin: 0;
}

.pxs-sl-sd .form_section {
    padding: 16px 13px;
    background: #fff;
}

.pxs-sl-sd .detail_list li {
    margin: 0 0 25px;
}

.pxs-sl-sd .detail_list li:last-child {
    margin: 0;
}

.pxs-sl-sd .detail_list {
    margin: 0;
}

.pxs-sl-sd .detail_list li span {
    font-size: 13px;
    color: #797979;
    font-weight: 400;
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.pxs-sl-sd .detail_list li span.detail_topic {
    min-width: 111px;
    text-transform: capitalize;
}

.pxs-sl-sd .detail_list li span i {
    margin-right: 7px;
}

.pxs-sl-sd .detail_list li select, .pxs-sl-sd .detail_list li input {
    font-size: 13px;
    color: #797979;
    font-weight: 400;
    border: 1px solid #e2e2e4;
    border-radius: 5px;
    min-height: 32px;
    padding: 10px 17px 10px 12px;
    width: 80%;
}

.pxs-sl-sd .detail_list li select {
    width: 220px;
}

::-webkit-input-placeholder {
    opacity: 1;
}

:-moz-placeholder {
    opacity: 1;
}

::-moz-placeholder {
    opacity: 1;
}

:-ms-input-placeholder {
    opacity: 1;
}

.form_right {
    display: inline-block;
}

.output_text {
    margin-right: 14px;
    margin-left: 45px;
}

.drag_drop_section {
    background: #fff;
    padding: 0 12px 35px;
}

.drag_box {
    background: #f0f2f7;
    min-height: 442px;
    padding: 15px;
    margin: 0 0 13px;
    position: relative;
}

.bill_btn {
    width: 100%;
    background: #D4F500;
    font-size: 18px;
    color: #ffffff;
    padding: 15px;
    font-weight: 400;
    border-radius: 5px;
    border: none;
    text-align: center;
    display: block;
}

.bill_btn:hover {
    color: #ffffff;
}

.korrek_head {
    float: left;
    font-size: 16px;
    color: #797979;
    font-weight: 400;
    background: none;
    padding: 0;
    border: none;
}

.korrek_head:hover {
    background: none;
    color: #797979;
}

.btn_right {
    float: right;
    position: relative;
}

.page3 .dynamic_pop_up {
    float: right;
    position: relative;
}

.page3 .dynamic_pop_up .bill_typ_pop {
    left: -137px;
    transform: none;
    -webkit-transform: none;
    -moz-transform: none;
    width: 202px;
}

.page3 .btn_right {
    float: none;
}

.date_text {
    float: right;/* margin-right:10%;*/
    font-size: 13px;
    color: #797979;
    font-weight: 400;
}

.date_text small {
    padding: 0 10px;
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
}

.wrapper_dagsec .panel-heading, .drag_drop_section .panel-heading {
    background: #ddefc5;
    padding: 13px 6px 13px 15px
}

.wrapper_dagsec .panel-heading-even {
    background: #cdc8e9;
}

.wrapper_dagsec .panel-body {
    padding: 11px 25px;
    position: relative;
}

.wrapper_dagsec .heading_list, .drag_drop_section .heading_list {
    margin: 10px 0 0;
    position: relative;
    float: left;
}

.wrapper_dagsec .heading_list li, .drag_drop_section .heading_list li {
    margin-bottom: 20px;
    float: none;
    font-size: 13px;
    color: #797979;
    font-weight: 400;
    position: relative;
    padding: 0;
    border: none;
    background: none;
    display: block;
    text-align: left;
}

.wrapper_dagsec .heading_list li:last-child, .drag_drop_section .heading_list li:last-child {
    margin: 0;
}

.right_date {
    margin-left: 5px;
    color: #797979;
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
}

.wrapper_dagsec .detail_icon {
    cursor: pointer;
    display: inline-block;
    left: 17px;
    position: absolute;
    top: 20px;
}

.wrapper_dagsec .input_output, .drag_drop_section .input_output {
    float: right;
}

.wrapper_dagsec .input_output li, .drag_drop_section .input_output li {
    margin-bottom: 10px;
}

.wrapper_dagsec .input_output li:last-child, .drag_drop_section .input_output li:last-child {
    margin-bottom: 0px;
}

.wrapper_dagsec .input_output strong, .drag_drop_section .input_output strong {
    display: inline-block;
    min-width: 72px;
    font-weight: 700;
    color: #797979;
    font-weight: 13px;
}

.wrapper_dagsec  .employee_pic, .drag_drop_section .employee_pic {
    float: right;
    margin-right: 14px;
    padding: 8px 9px 8px 0;
    border-right: 1px solid #e2e2e4;
}

/*end*/
.left_height {
    height: 500px;
    border: 1px solid red;
}

.drag_drop_section .input_output li span {
    display: inline-block;
    padding: 8px;
}

.drag_drop_section .input_output li:last-child {
    border: 1px solid #e2e2e4;
    border-radius: 5px;
}

.drag_drop_section .input_output li span.time_value {
    border-right: 1px solid #e2e2e4;
}

.pop_up small {
    position: absolute;
    right: 9px;
    top: 83%;
}

.pop_up small i {
    color: #a0a0a0;
    font-size: 27px;
    line-height: 1;
    position: absolute;
    right: 93px;
    top: 85%;
}

.pop_up_list {
    margin: 0;
}

.pop_up_list li {
    float: left;
    width: 157px;
    margin-right: 13px;
    border-radius: 5px;
    position: relative;
}

.pop_up_list .input-append .form-control {
    background: #ffffff none repeat scroll 0 0;
    height: auto !important;
    padding: 10px 12px 10px 42px;
    width: 100%;
}

.pop_up_list li  input, .pop_up_list li  select {
    width: 100%;
}

.pop_up_list li:last-child {
    margin-right: 0;
}

.pop_up_list li select, .pop_up_list li input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    background: #fff;
    min-height: 34px;
    padding: 7px;
    border-radius: 5px;
}

.pop_up_list li input {
    padding: 8px 10px 8px 45px;
}

.date_box, .from_box {
    margin: 15px;
    float: left;
    position: relative;
}

.date_box input, .from_box input {
    width: 100%;
    background: #fff;
    min-height: 34px;
    border-radius: 5px;
    border: 1px solid #e2e2e4;
    cursor: pointer;
    font-size: 13px;
    color: #797979;
    font-weight: 400;
    text-transform: lowercase;
    padding: 5px 13px 5px 65px;
    width: 156px;
}

.icon_portion {
    position: absolute;
    height: 100%;
    background: #f0f2f7;
    width: 45px;
    top: 0;
    left: 0;
    border-radius: 5px 0 0 5px;
}

.icon_portion i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    font-size: 20px;
}

.dataTables_length {
    float: left;    /*width: 50%;*/
    width: 25%;
}

/*.dataTables_filter
{
    display:none;
}*/
.dataTables_filter1 {
    display: block;
}

.dataTables_filter label {
    width: auto;
}

.child {
    position: relative;
}

div.dataTables_filter label {
    left: 0;
    position: absolute;
    top: 21%;
    width: 65%;
}

.page3 div.dataTables_filter label {
    float: left;
    width: 100%;
}

div.dataTables_filter label input {
    width: 80% !important;
    margin-left: 10px;
}

.aufraumen i {
    margin-right: 12px;
}

.dataTables_filter {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 40%;
}

.toolbar {

    /*width:61%;*/
    width: 36%;
    float: left;
}

.date_portion {
    float: right;
}

.pop_up_list_icon {
    position: absolute;
    top: 0;
    height: 100%;
    width: 34px;
    background: #f0f2f7;
    left: 0;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    z-index: 99999;
}

.pop_up_list_icon i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
}

.time_slot {
    display: inline-block;
    width: 270px;
    border: 1px solid #e2e2e4;
    border-radius: 5px;
    min-height: 32px;
    float: right;
    margin-right: 6%;
}

.time_slot  span {
    display: inline-block;
    color: #797979;
    font-size: 13px;
    font-weight: 300;
    padding: 10px 17px 10px 12px;
    width: 40%;
}

.drag_item .input_result .dynamic_time {
    padding: 0 0 0 17px !important;
}

.drag_item .output_result strong {
    padding: 8px 0;
}

.time_slot  span:first-child {
    border-right: 1px solid #e2e2e4;
}

.pop_date {
    position: relative;
}

.pop_date  .pop_up_list li select {
    position: relative;
    background: #ffffff url("../img/down.jpg") no-repeat scroll 92% 17px;
}

.pop_date  .pop_up small {
    left: 63%;
    right: auto;
}

.check_btn {
    background: none repeat scroll 0 0 #BBCA02;
    border-radius: 5px;
    display: inline-block;
    padding: 3px 5px;
    position: absolute;
    right: 14px;
    top: 30px;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.check_btn i {
    color: #fff;
    font-size: 15px;
}

.pop_date .pop_up_list li {
    margin-right: 18px;
}

.bill_deatil_page {
    padding-top: 10px;
    margin: 0;
}

.bill_deatil_page .child, .bill_deatil_page .dataTables_info, .bill_deatil_page .dataTables_paginate {
    display: none;
}

.first_row_table td {
    background: #ececec;
}

.table_cell_odd td {
    background: #fbfbfb;
}

.adv-table table tr td p {
    margin-bottom: 18px;
    margin-left: 48px;
}

.adv-table table tr td small {
    display: block; /*margin-bottom:22px;*/
    font-size: 13px;
}

.center {
    text-align: center;
}

.clock_time {
    position: relative;
}

.am_cell .pop_up {
    display: none;
    top: -71px;
    transform: translateX(37%);
    -webkit-transform: translateX(37%);
    -moz-transform: translateX(37%);
}

.am_cell .pop_up small {
    right: 30%;
}

.clock_popup {
    position: relative;
}

.heading_list .tooltip {
    background: #f00;
}

.date_portion {
    padding: 15px;
}

.input-append {
    float: left;
    width: 154px;
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
}

.input-append .input-group-btn {
    display: inline-block;
    height: 100%;
    left: -1px;
    margin: 0;
    position: absolute;
    top: -2px;
}

.input-append .input-group-btn .btn-danger {
    background: #f0f2f7;
    border: none;
    color: #797979;
    font-size: 13px;
    border-radius: none;
}

.input-append .form-control {
    padding: 6px 12px 6px 42px;
    background: #fff;
    width: 100%;
}

.abgere {
    display: inline-block;
    float: left;
    font-weight: normal;
    margin: 6px 0 0;
    padding: 15px;
    font-size: 13px;
    color: #797979;
}

.page2 .dataTables_filter {
    width: 38.5%;
}

.page2 div.dataTables_filter label {
    width: 100%;
}

.pop_up_list .input-append {
    margin: 0;
}

html body .pop_up_list .input-append .form-control {
    height: 34px !important;
    padding: 10px 12px 10px 42px;
}

.heading_list .tooltip {
    border: none;
    background: none !important;
    padding: 0;
}

.panel-heading .tooltip {
    border: none;
    background: none !important;
    padding: 0;
}

.tooltip {
    border: none;
    padding: 0;
    background: none;
    left: 10% !important;
    right: auto !important;
    transform: none !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
}

.tooltip-inner {
    font-size: 10px;
    display: inline-block;
    font-weight: 600;
    border: none;
    background: #a0a0a0;
    color: #fff;
    display: block;
    position: relative;
}

.tooltip-inner:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    background: url("../img/arrow_down.png") no-repeat;
    height: 10px;
    width: 20px;
    z-index: 9999999;   /*width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent; 
    border-top:10px solid #a0a0a0;*/
}

.tooltip-arrow {
    display: none;
}

/*page1 css start here*/
.page1 .add-on .btn {
    padding: 9px;
}

.aufraumen {
    display: inline-block;
    font-size: 13px;
    color: #797979;
    background: #f0f2f7;
    padding: 7px 28px;
    border: 1px solid #e2e2e4;
    border-radius: 3px;
    font-weight: bold;
}

.page1 .pop_up ul li .add-on .btn {
    margin: 0;
    padding: 9px;
}

/*page1 css end here*/

/*page2 css start here*/
.page2 .add-on .btn {
    padding: 9px;
}

/*page2 css end here*/

/*login page css start here*/

html, #login_page {
    position: relative;
    height: 100%;
}

#login_page {
    background: url('../img/bgimg.png') no-repeat center top;
    border-bottom: 10px solid #D4F500;
    position: relative;
    background-size: cover;
}

#login_page .user_pic {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    top: 0;
    margin-top: -43px;
}

#login_page .user_input:focus, #login_page .user_input:active {
    border: 0;
    box-shadow: none;
}

#login_page .anmelden_btn {
    background: #D4F500 none repeat scroll 0 0;
    border-radius: 5px;
    color: #ffffff;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    left: 50%;
    margin-left: -1px;
    margin-right: 0;
    margin-top: -18px;
    padding: 7px 16px;
    position: absolute;
    text-transform: capitalize;
    top: 100%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
}

#login_page .arrow_icon {
    display: inline-block;
    vertical-align: middle;
    margin-left: 11px;
}

#login_page .footer_icon {
    position: absolute;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
}

/*login page css end here*/

/*lock_screen start here*/
.login_section h3 {
    color: #ffffff;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 400;
    margin: 0 0 12px;
    text-align: center;
}

.login_section span {
    text-align: center;
    display: block;
    color: #ffffff;
    font-size: 13px;    /*text-transform:lowercase;*/
}

.login-body2 .login-wrap {
    width: 226px;
}

#login_page .login-wrap .user_input2 {
    float: left;
    width: 184px;
    padding: 12px;
    color: #999999;
    font-size: 14px;
    font-weight: 400;
}

#login_page.login-body2 .mit_einem {
    display: inline-block;
    text-align: center;
    color: #a7a7a7;
    font-size: 13px;
    font-weight: 400;
    margin: 18px 0 0;
}

body {
    min-height: 480px;
}

#login_page.login-body2 .login_section h3 {
    margin: 0 0 7px;
}

/*lock_screen end here*/
.pop_date {
    position: relative;
}

.login-body2 .form-signin {
    margin-top: -20px;
    position: absolute;
    top: 100%;
}

.date_value {
    color: #BBCC00;
    margin-left: 6px;
    display: inline-block;
}

.bill_type {
    position: relative;
}

.bill_typ_pop {
    background: none repeat scroll 0 0 #A0A0A0;
    border-radius: 5px;
    left: 50%;
    padding: 10px;
    position: absolute;
    top: -54px;
    transform: translateX(-50%);
    width: 305px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    display: none;
    z-index: 9999;
}

.bill_typ_pop ul li.active {
    outline: 1px solid #fff;
}

.bill_typ_pop ul li {
    border-radius: 2px;
    float: left;
    margin-right: 21px;
    padding: 2px 10px;
    background: #A9D86E;
}

.bill_typ_pop ul li:nth-child(2) {
    background: #8175C7;
}

.bill_typ_pop ul li:nth-child(3) {
    background: #FCB322;
}

.bill_typ_pop ul li  a {
    color: #fff;
    font-weight: 700;
    font-size: 9.75px;
    text-transform: capitalize;
    display: inline-block;
    line-height: 13.93px;
}

.bill_typ_pop ul {
    margin: 0;
}

.bill_typ_pop ul .check_btn {
    position: static;
    transform: none;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
}

.bill_typ_pop ul li:last-child {
    margin-right: 0px;
    background: none;
    padding: 0;
}

.bill_typ_pop small {
    font-size: 21px;
    left: 50%;
    position: absolute;
    top: 30px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    color: #A0A0A0;
}

.am_section {
    position: relative;
}

.pop_date .pop_up {
    left: 50%;
    top: -57px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    width: 582px;
    display: none;
    background: #a0a0a0 none repeat scroll 0 0;
    border-radius: 5px;
    padding: 14px;
    position: absolute;
    z-index: 999;
}

.am_cell {
    width: 105px !important;
}

*, :before, :after {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.cell7 {
    width: 111px !important;
}

.cell7 button {
    margin-right: 5px;
    text-align: center;
}

.row_data td textarea {
    border: none;
    padding: 0;
    background: #F9F9F9;
    resize: none;
    width: 100%;
    overflow: auto;
}

.row_data td textarea.time_edit {
    display: inline-block;
    height: 20px;
    width: 75%;
}

.row_data td textarea.date_text {
    height: 20px;
}

.hide {
    display: none;
}

.show {
    display: block;
}

.save_cancel  button {
    background: rgba(0, 0, 0, 0);
    border: none;
    color: #667FA0;
    display: inline-block;
    font-size: 13px;
    margin: 0 5px;
    padding: 0;
    text-transform: capitalize;
}

.gallery {
    display: none;
}

td.details table tr td textarea {
    border: none;
    background: rgb(238, 238, 238);
    width: 100%;
    height: 20px;
    resize: none;
}

td.details table tr td textarea.time_edit {
    display: inline-block;
    height: 20px;
    width: 75%;
}

.pop_date .pop_up_list li .prsn_drop {
    padding-left: 40px;
    background: #fff;
    background-image: none;
}

tabel tr td img {
    max-width: 100%;
    max-height: 100%;
}

/*tr.even td textarea{
    background-color: #FFFFFF;
}*/
.row_data td textarea {
    background-color: #FFFFFF;
}

/*.row_data td.emp_pic img 
{
max-width:100%;
}*/
.row_data.odd td textarea {
    background-color: rgb(249, 249, 249);
    vertical-align: middle;
}

.korrek_head textarea {
    background: rgba(0, 0, 0, 0);
    border: medium none;
    height: 20px;
    padding: 0;
    resize: none;
    width: auto;
}

.time_slot span {
    padding-left: 40px !important;
}

.time_slot span textarea {
    border: medium none;
    float: right;
    height: 20px;
    resize: none;
    width: 100%;
}

.time_slot span i {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
}

.input_output .input_result, .input_output .output_result {
    width: 140px;
}

.input_output .input_result  strong, .input_output .output_result strong {
    min-width: auto;
    float: left;
}

.input_output .input_result span, .input_output .output_result  span {
    float: left;
    margin-left: 10px;
    width: 70px;
}

.input_output .input_result span i, .input_output .output_result  span i {
    float: left;
}

.input_output .input_result span textarea, .input_output .output_result  span textarea {
    border: medium none;
    display: inline-block;
    float: left;
    height: 20px;
    padding: 0;
    resize: none;
    vertical-align: middle;
    width: 52px;
}

.bill_type_sec {
    position: relative;
    float: right;
}

.tabel2_section tr td span {
    overflow: hidden;
}

.tabel2_section tr td textarea {
    background: none;
    border: none;
    resize: none;
    width: 100%;/* remove add vikash*/

    /*height: 20px;*/
    float: none;
}

.tabel2_section  .cell_info {
    display: block;
    overflow: hidden;
    position: relative;
}

.tabel2_section  .cell_info i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
}

/*.tabel2_section   textarea
{
float:left;
max-width:90px !important;
width:100% !important;
}*/
.aberecchnet {
    background: #bfc2cd !important;
    color: #fff;
}

.disable_row {
    background: #f1f0f0 !important;
}

.drag_item .pop_up_box {
    position: absolute;
    top: 0;
    left: 0;
}

.drag_item .pop_up_box .pop_up_list {
    background: #a0a0a0;
    padding: 14px 45px 14px 15px;
    border-radius: 5px;
}

.drag_item .pop_up_box {
    bottom: 100%;
    left: 0;
    position: absolute;
    z-index: 9999;
    left: -306px;
    width: 542px;
}

.drag_item .pop_up_box .pop_up_list li {
    width: 150px;
}

.drag_item .pop_up_box .task_details {
    top: 29px;
}

.drag_item .input-append .input-group-btn .btn-danger {
    padding: 8px;
}

.page3 .date_text {
    position: relative;
}

.date_pop_check {
    left: -190px;
    position: absolute;
    top: -7px;
    width: 120px;
    display: none;
}

.date_correct {
    right: -65px;
    top: 17px;
}

.date_pop_check .add-on button {
    padding: 8px !important;
}

.drag_popup .pop_up_list {
    background: #a0a0a0 none repeat scroll 0 0;
    border-radius: 5px;
    padding: 14px 45px 14px 15px;
}

.drag_popup .pop_up_list li {
    width: 150px;
}

.drag_popup .add-on .btn {
    padding: 8px 9px !important;
}

.drag_popup {
    left: -360px;
    position: absolute;
    width: 536px;
    z-index: 2147483647;
    top: -11px;
}

.pop_up {
    display: none;
}

.page_bill tr td textarea {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    resize: none;
    width: 100%;
}

/*.page_bill tr td span  i 
{
float:left;
}*/

/*.page_bill tr td span textarea
{
float:left;


}*/
.page_bill .pop_up {
    background: #a0a0a0 none repeat scroll 0 0;
    border-radius: 5px;
    display: none;
    left: 50%;
    padding: 14px;
    position: absolute;
    top: -57px;
    transform: translateX(-50%);
    width: 560px;
    z-index: 99999;
}

.gallery {
    text-align: center;
}

.page_bill .date_text {
    height: 20px;
}

.gallery  a {
    color: #000;
    font-size: 10px;
    text-decoration: underline;
}

.page_bill .add-on .btn {
    padding: 9px;
}

.clearfix:after {
    clear: both;
    content: ' ';
    display: block;
    font-size: 0;
    line-height: 0;
    visibility: hidden;
    width: 0;
    height: 0;
}

.clearfix {
    display: inline-block;
}

* html .clearfix {
    height: 1%;
}

.clearfix {
    display: block;
}

.panel_body table.test_class {
    max-width: auto !important;
}

/*9-1-2016 start here*/
.pointer, .prsn_drop, .bill_type {
    cursor: pointer;
}

/*9-1-2016 end here*/

/*10-1-2017 start here*/
.page3 .bill_typ_pop ul li {
    line-height: 0;
}

.page3 .bill_typ_pop ul li a {
    padding: 4px 0;
}

.page3 .bill_typ_pop ul li:last-child a {
    padding: 3px 5px;
}

.bill_detail_page .tabel {
    min-width: 1050px;
    overflow: auto;
}

.bill_detail_page .page_bill .add-on .btn i {
    margin: 0;
}

td {
    color: #797979 !important;
}

.page2 .output_cell2, .page2 .output_cell1 {
    width: 125px;
}

.subtasks {
    display: none;
}

/*page 2 css start here*/
.page2 .cell_info {
    position: relative;
}

.page2 .tabel2_section   textarea {
    max-width: 100% !important;
}

/*.page2 .tabel2_section .cell_info i {
   float: none;
   margin:0;
   display:inline-block;
   position:absolute;
   top:6px;
   left:0;
  transform:none
}*/
.page2 .tabel2_section  .cell_info_2 textarea {
    margin-left: 15px;
}

.page2 .abgerechnet_text {
    background: #bec2cd;
    color: #fff;
    display: inline-block;
}

.page2 .disable {
    background: #f1eff0 !important;
}

/*page 2 css end here*/

/*page 1 css start here */
.time_info {
    position: relative;
    width: 100%;
    padding-left: 16px;
}

.time_info i {
    position: absolute;
    left: 0;
    padding-top: 5px;
    position: absolute; /*top:50%;
    left:0;
    transform:translateY(-50%);
    -webkit-transform:translateY(-50%);
    -moz-transform:translateY(-50%);*/
}

tr.even .time_info i {
    padding-top: 4px;
}

/*.page1 .time_info i 
{
transform:none;
-webkit-transform:none;
-moz-transform:none;
top:4.5px;
}*/
.time_info textarea {
    width: 100% !important;
}

/*page 1 css end here */

/*page3 css start here*/

/*.bill_cell0
{
width:49px;
}
.bill_cell1
{
width:399px;
}
.bill_cell2
{
width:290px;
}
.bill_cell3
{
width:320px;
}
.bill_cell4
{
width:45px;
}
.bill_cell5
{
width:111px;
}
.bill_cell6
{
width:108px;
}
.bill_cell7
{
width:176px;
}
.bill_cell9
{
width:52px;
}
.bill_cell10
{
width:112px;
}*/

/*.bill_detail_page
{
background:url("../img/bill.jpg") no-repeat center top;
}*/

/*.bill_deatil_page_inner {
    margin-top: 11px;
    padding-left: 0;
}*/

/*#bill_detail_wrapper
{
opacity:0.8;
}*/
.page3_time {
    position: relative;
    padding-left: 15px;
}

.bill_detail_page  tr td span.page3_time textarea {
    width: 100%;
    margin: 0;   /* line-height:17px;*/
}

.bill_detail_page  tr td span.page3_time i {

    /* left: 0 !important;
     position: absolute !important;
     top: 3px;
     transform: none;
     -webkit-transform:  none;
     -moz-transform:  none;*/
    position: absolute;
    top: 56%;
    left: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
}

/*page3 css end here*/

/*page4 css start here*/

.input_output .dynamic_time {
    position: relative;
    padding-left: 15px;
}

.input_output .dynamic_time i {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
}

.dynamic_time textarea {
    line-height: 20px;
}

.dynamic_input_time, .time_value {
    position: relative !important;
}

.dynamic_input_time .clock_icon, .time_value  .clock_icon {
    position: absolute !important;
    top: 50% !important;
    left: 55px !important;
    transform: translateY(-50%) !important;
    -webkit-transform: translateY(-50%) !important;
    -moz-transform: translateY(-50%) !important;
}

.time_value {
    width: 108px;
    padding-left: 10px !important;
}

.output_cost {
    width: 108px;
    position: relative;
}

.output_cost span {
    position: relative;
}

.output_cost span i {
    position: absolute;
    left: -3px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
}

.time_value  .clock_icon {
    left: 10px !important;
}

.project_name i {
    position: static !important;
    padding-right: 10px;
}

.left_part {
    float: left;
    width: 50%;
}

.right_part {
    width: 50%;
    float: right;
}

/*page4 css end here*/

/*login_wrapper start here*/

/*#login_page
{
    background:url("../img/psd1.jpg") no-repeat center top;
}
.login_wrapper
{
    opacity:0.8;
}*/
#login_page h2 {
    font-size: 18px;
    font-weight: 400;
    color: #d8d8d8;
    text-align: center;
    padding: 14px 16px 16px;
    border-bottom: 1px solid #96a0a4;
    margin: 0;
}

#login_page .login_section {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    left: 50%;
    padding: 63px 54px 27px;
    position: absolute;
    top: 36.5%;
    transform: translateX(-50%);
    width: 331px;
}

#login_page .user_input {
    background: #fff none repeat scroll 0 0;
    border: medium none;
    border-radius: 5px;
    color: #999999;
    font-size: 14px;
    font-weight: 400;
    height: auto;
    margin-bottom: 7px;
    padding: 7px;
    text-transform: capitalize;
    width: 100%;
}

/*end*/

/*login-body2 css start here*/
#login_page.login-body2 .login_section {
    padding: 61px 52px 41px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 8px;
}

.arrow_icon_right {
    background: #D4F500 none repeat scroll 0 0;
    display: inline-block;
    float: right;
    padding: 9px 13px;
    border-radius: 5px;
}

#login_page.login-body2 .login-wrap .user_input2 {
    color: #999999;
    float: left;
    font-size: 14px;
    font-weight: 400;
    padding: 8px;
    width: 184px;
}

#login_page.login-body2 .mit_einem {
    color: #a7a7a7;
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    margin: 13px 0 0;
    text-align: center;
}

.grater_symbol {
    margin-right: 7px;
}

/*end*/
span .time_edit, span.date_info textarea.edit_text {
    height: 20px !important;
}

.date_height  textarea, .output_cell1 textarea, .output_cell2 textarea {
    height: 20px !important;
}

.page3_time textarea, .am_text textarea {
    height: 20px !important;
}

.right_icon2 {
    color: #D4F500 !important;
}

.page2 .action_btn a {
    margin: 0 2px;
}

.bill_detail_page .wrapper {
    margin-top: 71px;
}

.bill_deatil_page_inner {
    padding-left: 0;
}

.bill_deatil_page_inner .panel-body {
    padding: 33px 15px 15px;
}

.bill_deatil_page_inner .adv-table table tr td p {
    margin: 0 0 0 38px !important;
}

.page3 .dynamic_time textarea {
    height: 20px !important;
}

.page3 .project_name {
    display: block;
}

.date_text em {
    font-style: normal !important;
    font-size: 13px;
}

.page3 .korrek_head {
    text-transform: capitalize !important;
}

.page3 .time_value textarea {
    height: 20px !important;
}

.page3 .output_text {
    margin-left: 12%;
}

.page3 .time_slot textarea {
    height: 20px !important;
}

.page3  .date_text {
    margin-right: 10px;
}

.page3 .output_detail, .page3 .time_value, .page3 .output_cost {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.cell_info_2 textarea {
    height: 20px !important;
}

/*#login_page
{
    background:url('../img/psd.jpg') no-repeat center top;
}*/
.login_wrapper {
    opacity: 0.8;
}

#dynamic-table_length label, #hidden-table-info_filter label, .pull-right a {
    font-weight: 400;
}

.cell6_text {
    position: relative;
    padding-left: 16px;
}

.cell6_text .clock_icon {
    position: absolute;
    left: 0;
    padding-top: 6px;
    position: absolute; /*top:50%;
        left:0;
        transform:translateY(-50%) !important;
        -webkit-transform:translateY(-50%) !important;
        -moz-transform:translateY(-50%) !important;*/
}

.date_value.end_date {
    font-size: 13px;
    font-family: "Open Sans", sans-serif !important;
}

.edit_text.time_edit {
    line-height: 21px;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 300 !important;
}

.page3 .bill_type_sec .bill_typ_pop {
    width: 202px;
}

.lock_unlock {
    text-align: center;
}

.einamaling {
    padding-left: 11px;
}

.select-type {
    display: none;
}

.einmalig {
    display: none;
}

.einmalig.active {
    display: inline-block;
}

.select-type.active {
    display: block;
}

.bill_detail_page .cell12 {
    background: #ececec !important;
}

.bill_detail_page .cell13 {
    background: #fff !important;
}

.bill_detail_page .cell14 {
    background: #fbfbfb !important;
}

.bill_detail_page .cell15 {
    background: #fff !important;
}

.bill_detail_page .cell16 {
    background: #fbfbfb !important;
}

.active_date {
    color: #D4F500;
}

.right_part .input_output .dynamic_input_time_dynamic strong {
    min-width: auto;
    float: left;
    display: inline-block;
    font-weight: 700;
    color: #797979;
    font-weight: 13px;
}

.right_part .input_output .dynamic_input_time_dynamic .dynamic_time {
    float: left;
    margin-left: 10px;
    width: 70px;
    position: relative;
    padding: 0 0 0 15px;
}

.wrapper_dagsec .input_output strong, .drag_drop_section .input_output strong {
    display: inline-block;
    font-weight: 700;
    color: #797979;
    font-size: 13px;
    min-width: auto;
    float: left;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .wrapper_dagsec .input_output strong, .drag_drop_section .input_output strong {
        display: inline-block;
        font-weight: 700;
        color: #797979;
        font-size: 13px;
        min-width: auto;
        float: left;
    }

}

.right_part .input_output .dynamic_input_time_dynamic .dynamic_time i {
    position: absolute !important;
    left: 0 !important;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
}

.right_part .input_output .dynamic_input_time_dynamic .dynamic_time textarea {
    position: relative;
    resize: none;
    padding-left: 15px;
    border: medium none;
    display: inline-block;
    float: left;
    height: 20px;
    padding: 0;
    resize: none;
    vertical-align: middle;
    width: 52px;
}

.page1 span .time_edit, span.date_info textarea.edit_text {
    margin-right: 0;
    font-size: 13px;
}

#hidden-table-info table td {
    vertical-align: top;
    padding: 0;
}

.emp_pic {
    position: relative;
}

.gallary_pic_list li {
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
}

.gallary_pic_list li  img {
    width: 29px;
    height: 29px;
    cursor: pointer;
}

.gallary_pic_list.active {
    display: block;
}

.gallary_pic_list {
    background: #fff none repeat scroll 0 0;
    border: 2px solid #000;
    display: none;    /*height: 200px;*/
    left: -10px;
    overflow: auto;
    position: absolute;
    text-align: center;
    top: 48px;
    width: 64px;
    z-index: 999;
}

.active_date {
    font-family: "Open Sans", sans-serif;
}

.zuruk {
    padding: 18px 17px 0px;
}

.zuruk .zuruk_btn {
    display: block;
    color: #797979;
    font-size: 13px;
    font-weight: 400;
    width: 165px;
    text-align: center;
    background: #fff;
    border: 1px solid  #ddd;
    padding: 10px;
    margin-bottom: 28px;
    border-radius: 3px;
}

.zuruk  .auftraggeber {
    display: block;
    font-size: 13px;
    font-weight: 700;
    text-transform: capitalize;
}

.zuruk  .auftraggeber span {
    font-weight: 300;
    margin-left: 31px;
}

.drag_drop_section .input_output strong {
    display: none;
}

.sortable .time_value textarea {
    border: medium none;
    resize: none !important;
    width: 80px;
}

/*.sortable .output_cost
{
    display:none;
}*/
.sortable .output_cost {
    width: 60px;
    float: right;
}

.sortable .time_value {
    display: none;
}

.sortable .output_cost textarea {
    border: medium none;
    resize: none !important;
    width: 80px;
    padding-left: 9px;
    height: 20px !important;
}

.overflow textarea {
    overflow: hidden;
}

.drag_item .time_value {
    float: left;
    width: 50%;
}

.drag_item .time_value textarea {
    resize: none;
    border: none;
}

.drag_item .time_value textarea {
    width: 50px;
}

.drag_item .output_cost textarea {
    width: 53px !important;
}

.drag_item .input_output {
    width: 50%;
}

.drag_item .output_cost textarea {
    border: none;
    resize: none;
    height: 20px !important;
}

.drag_item .output_cost {
    float: left;
    width: 50%;
}

textarea {
    resize: none !important;
}

.wrapper_dagsec .output_detail .clock_icon {
    display: none;
}

.wrapper_dagsec #bill .output_detail .output_cost .clock_icon {
    display: block;
    left: -6px !important;
    top: 46% !important;
}

.dataTables_info {
    padding: 0;
}

.bill_detail_wrapper .relative  span {
    cursor: pointer;
}

.relative {
    position: relative !important;
}

.bill_detail_wrapper .bill_typ_pop {
    width: 210px !important;
}

.bill_detail_wrapper .bill_typ_pop small {
    top: 34px !important;
}

.bill_detail_wrapper .am_text .date_text {
    height: 28px !important;
    padding: 0px;
}

.bill_detail_wrapper .am_text .date_info .input-append {
    margin-bottom: 0px;
    width: 100px;
}

.bill_detail_wrapper #support_user_id {
    padding-left: 34px;
}

.btn.btn-info.btn-xs.taskcomplete {
    background-color: #BFC1CD;
    border-color: #BFC1CD;
}

/*=============================Add By Me=======================*/
.bill_detail_page .output_text {
    width: 92px;
}

#bill_detail_section .time_slot span.total_bill_amt {
    padding-left: 18px !important;
}

#bill_detail_section .time_slot span.total_bill_amt i {
    left: 7px;
}

.login-wrap .anmelden_btn, .login-wrap .arrow_icon_right {
    border-color: #D4F500 !important;
}

.login-wrap .arrow_icon_right:hover {
    background-color: #D4F500 !important;
    border-color: #D4F500 !important;
    color: #ffffff;
}

.login-wrap .arrow_icon_right {
    padding: 7px 13px;
}

.freebie {
    background-color: #8175C7
}

.non_billable {
    background-color: #FCB322
}

.billable {
    background-color: #a9d86e
}

.bill_btn_gen {
    width: 100%;
    background: #D4F500;
    font-size: 18px;
    color: #ffffff;
    padding: 15px;
    font-weight: 400;
    border-radius: 5px;
    border: none;
    text-align: center;
    display: block;
}

.bill_btn_gen:hover {
    color: #ffffff;
}

textarea.btn {
    text-align: left;
}

textarea.btn:hover {
    color: #000000;
}

.sortable .time_value {
    float: right;
    width: 60px;
}

.date_info.start_date .input-append {
    margin-bottom: 0px;
}

.date_info.start_date .input-append textarea.edit_text {
    height: 20px !important;
}

.sortable .time_value .output_cost {
    display: block;
}

#bill_detail_section .time_slot span {
    padding-left: 25px !important;
}

.sortable .time_value textarea {
    padding-left: 9px;
}

.sortable .output_cost span i {
    top: 42%;
}

.no-sorting {
    background: none !important
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .sortable .output_cost span i {
        top: 35%;
    }

    #bill_detail_section .time_slot span i {
        top: 54%;
    }

}

/*.billlist .dataTables_filter{display: none !important;}*/

/**************Add by sv********************/
.start_date_textarea {
    padding-left: 43px !important;
}

/**************Add by sv : 06-02-2017********************/
.drag_item .pop_up_box .update_task_details {
    top: 29px;
}

.support_drp {
    padding-left: 40px !important
}

.start_date .input-append {
    width: 98px;
}

.locked-row {
    background-color: #C0C0C0 !important;
}

.closetasks td.class6 textarea.edit_text {
    height: 37px !important;
}

.closetasks td.class3 textarea.edit_text {
    height: 37px !important;
}

.closetasks span .time_edit, span.date_info textarea.edit_text {
    height: 37px !important;
}

.closetasks td.details table tr td textarea.time_edit {
    width: 38%;
}

.billlist textarea.transparent_border.border_text {
    width: 57px;
}

.billlist textarea.edit_text.date_text.mycustomecss {
    width: 100px !important;
    height: 31px !important;
    overflow: hidden !important;
}

textarea.transparent_border.border_text {
    overflow: hidden !important;
}

.funselectproject {
    min-width: 110px !important;
    width: 110px !important;
}

.funselectclient {
    min-width: 110px !important;
    width: 110px !important;
}

.Abgerechnet_checkbook {
    float: left;
    padding: 5px;
}

.closetasks td, .closetasks th {
    padding: 5px !important;
    min-width: inherit !important;
    max-width: inherit !important;
}

.closetasks .class1 {
    width: 4% !important;
}

.closetasks .class2 {
    width: 8% !important;
}

.closetasks .class3 {
    width: 14% !important;
}

.closetasks .class4 {
    width: 12% !important;
}

.closetasks .class5 {
    width: 12% !important;
}

.closetasks td.margethreecol1, .closetasks th.margethreecol1 {
    width: 38% !important;
}

.closetasks .class6 {
    width: 3% !important;
}

.closetasks .class7 {
    width: 10% !important;
}

.closetasks .class8 {
    width: 8% !important;
}

.closetasks .class9 {
    width: 8% !important;
}

.closetasks .class10 {
    width: 8% !important;
}

.closetasks td.margethreecol2, .closetasks th.margethreecol2 {
    width: 24% !important;
}

.closetasks .class11 {
    width: 8% !important;
}

.closetasks table td.details {
    padding: 0 !important;
}

.closetasks th * {
    width: auto !important;
}

.closetasks td.details table tr td textarea.time_edit {
    display: inline-block;
    height: 20px;
    width: 84%;
}

@media screen and (max-width:1440px) {
    .closetasks .btn, .closetasks .btn-xs {
        padding: 0px 2px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
    }

    .closetasks td, .closetasks th {
        padding: 3px !important;
    }

    td.details table tr td textarea {
        width: 98%;
    }

}

/**********add by SV*************/
.textarea-bg-color textarea {
    background-color: #ececec !important;
    border: 1px solid #ececec !important;
}

.closetasks td.last_list_icon div.action_btn {
    float: right;
    margin-right: 8px;
}
.navbar-toggle {
    display:none;
    position: absolute;
    right: 0;
    top: 12px;
}
.navbar-toggle .icon-bar {
    background:#134759;
}
#mainnavigation.collapse.in + #main-content { padding-top:20px; }
@media (min-width:900px) {
    #mainnavigation.collapse {
        display:block;
    }
    .navbar-toggle {
        display:none !important;
    }

}
@media (max-width:900px) {
    #main-content {
        margin-left: 0;
    }
    #mainnavigation {
        /*display:none;*/
    }
    #sidebar {
        position: static;
        width: 100%;
    }
    #sidebar .sync-status {
        position: static;
        padding: 15px 0 15px 10px;
    }
    a.logo {
        width:100%;
    }
    .logo_section {
        width: 120px;
    }
    .header_right {
        padding-left: 120px;
    }
    #section-title {
        font-size: 26px;
    }
    .navbar-toggle {
        /*display:block;*/
    }
}